import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import SubHeader from "../Profile/SubHeader";
import { EVENTS } from "../../utilities/queries";
import { useAuth0 } from "../Auth0Provider";

function SellerMissions({ client, ...props }) {
  const { user } = useAuth0();
  const { loading, error, data } = useQuery(EVENTS, {
    variables: { seller: user["https://poip.io/username"] },
    client
  });
  if (loading && !data) return <Loading />;
  if (error) return <ErrorView error={error} />;

  return (
    <Grid container style={{ marginBottom: "25px", flexGrow: 1 }}>
      <div style={{ height: "50px" }} />
      <SubHeader />
      <Grid container style={{ marginTop: "44px" }}>
        <Grid item xs={12} sm={5}>
          <Container>
            <Box bgcolor="black" width="85%" height="4px" marginTop="45px" />
            <Typography
              variant="subtitle1"
              style={{ marginTop: "40px", marginBottom: "20px" }}
            >
              Proof of Impact
            </Typography>
            <Typography variant="h2">Your Delivered Impact</Typography>
          </Container>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box flexGrow={1} bgcolor="#e0dcd5" height="110%">
            <Container style={{ padding: "50px" }}>
              <Table>
                <TableBody>
                  <TableRow style={{ borderBottom: "solid 2px #16161a" }}>
                    <TableCell>
                      <Typography variant="subtitle1">
                        Mission Impact
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Date</Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  {data.events.map((event, idx) => (
                    <TableRow
                      key={idx}
                      style={{ borderTop: "dotted 2px #16161a" }}
                    >
                      <TableCell>
                        <Typography variant="subtitle1">
                          {event.amount} {event.token.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {new Date(event.timestamp).toString()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <a
                          href={`https://ipfs.poip.io/ipfs/${event.ipfsHash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography variant="subtitle1">Report</Typography>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withApollo(SellerMissions);
