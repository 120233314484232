import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#4371cc"
      },
      secondary: {
        main: "#e0dcd5"
      }
    },
    status: {
      danger: "orange"
    },
    overrides: {
      MuiButton: {
        root: {
          "&:hover": {
            backgroundColor: "none"
          }
        }
      }
    },
    typography: {
      fontFamily: "proxima_novaregular, Arial, Helvetica, sans-serif",
      h1: {
        fontWeight: "bold",
        letterSpacing: "-0.5px",
        lineHeight: "1.06",
        fontSize: "4.571rem" //64px
      },
      h2: {
        fontWeight: "bold",
        letterSpacing: "-0.4px",
        fontSize: "3.714rem" //52px
      },
      h3: {
        fontWeight: "bold",
        letterSpacing: "-0.25px",
        fontSize: "2.857rem" //40px
      },
      h4: {
        letterSpacing: "-0.25px",
        fontSize: "2.286rem", //32px
        lineHeight: "1"
      },
      h5: {
        fontWeight: "bold",
        letterSpacing: "-0.25px",
        lineHeight: "1.33",
        fontSize: "1.714rem" //24px
      },
      h6: {
        letterSpacing: "-0.25px",
        fontSize: "1.286rem", //18px
        lineHeight: "1.56"
      },
      body1: {
        letterSpacing: "-0.2px",
        fontSize: "1.429rem", //20px
        lineHeight: "1.1"
      },
      subtitle1: {
        letterSpacing: "-0.2px",
        lineHeight: "1.27",
        fontSize: "1.571rem" //22px
      },
      subtitle2: {
        letterSpacing: "0px",
        fontSize: "1rem" //14px
      }
    }
  })
);

export default theme;
