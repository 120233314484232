import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { ORDERS } from "../../utilities/queries";
import { APPROVEORDER } from "../../utilities/mutations";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import Button from "../shared/Button";

function OrderTable({ client }) {
  const { loading, error, data } = useQuery(ORDERS, {
    client
  });

  const [approveOrder, approveReturn] = useMutation(APPROVEORDER, {
    client,
    refetchQueries: [{ query: ORDERS }]
  });
  let orders = [];
  if (data && data.orders && data.orders.edges) {
    orders = data.orders.edges;
  }
  return (
    <>
      {error || approveReturn.error ? (
        <ErrorView error={error || approveReturn.error} />
      ) : loading || approveReturn.loading ? (
        <Loading />
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Outstanding Amount</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map(({ node }, idx) => (
                <TableRow key={idx}>
                  <TableCell>{node.name}</TableCell>
                  <TableCell>{new Date(node.processedAt).toString()}</TableCell>
                  <TableCell>
                    {node.totalCapturableSet.presentmentMoney.amount}{" "}
                    {node.presentmentCurrencyCode}
                  </TableCell>
                  <TableCell>
                    {node.lineItems.edges
                      .map(
                        ({ node }, idx) =>
                          `${node.title} - ${node.variantTitle} - ${node.quantity}`
                      )
                      .join(", ")}
                  </TableCell>
                  <TableCell>
                    {node.customAttributes[0]
                      ? node.customAttributes[0].value
                      : null}
                  </TableCell>
                  <TableCell>
                    {node.capturable ? (
                      <Button
                        onClick={() =>
                          approveOrder({ variables: { orderId: node.id } })
                        }
                      >
                        Approve
                      </Button>
                    ) : (
                      "Approved"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
}

export default withApollo(OrderTable);
