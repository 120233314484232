import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";

import envBg from "../../assets/images/buyerDash/david-troeger-9-xzy-ez-pahmi-unsplash.jpg";
import healthBg from "../../assets/images/buyerDash/david-troeger-9-xzy-ez-pahmi-unsplash-copy.jpg";
import socialBg from "../../assets/images/buyerDash/david-troeger-9-xzy-ez-pahmi-unsplash-copy-2.jpg";
import animalBg from "../../assets/images/buyerDash/david-troeger-9-xzy-ez-pahmi-unsplash-copy-3_2.jpg";
import { useAuth0 } from "../Auth0Provider";

const SmallFixedHeightPaper = styled(Paper)`
  height: 190px;
  background-color: #e0dcd5;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.bg});
  border-bottom: 5px solid ${props => props.color};
`;

const boxes = [
  { label: "ENVIRONMENT", group: "environment", bg: envBg, color: "#106640" },
  { label: "HEALTH", group: "health", bg: healthBg, color: "#4371cc" },
  { label: "SOCIAL", group: "social", bg: socialBg, color: "#e8aa46" },
  { label: "ANIMAL", group: "animal", bg: animalBg, color: "#de896f" }
];

function TypeTotals({ sdgGroupBreakdown }) {
  const { user } = useAuth0();
  return (
    <Grid container spacing={2}>
      {boxes.map(({ label, group, bg, color }, idx) => (
        <Grid item xs={6} key={idx}>
          <SmallFixedHeightPaper bg={bg} color={color}>
            <div
              style={{
                color: "white",
                marginLeft: "15px",
                marginRight: "15px",
                textShadow: "0px 0px 8px #000000"
              }}
            >
              <div style={{ height: "15px" }} />
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexWrap: "nowrap"
                }}
              >
                <svg height="20" width="20">
                  <circle cx="10" cy="10" r="10" strokeWidth="0" fill={color} />
                </svg>
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                >
                  <Typography variant="body1">{label}</Typography>
                </div>
              </div>
              <div style={{ height: "15px" }} />
              <Typography variant="h4">
                {sdgGroupBreakdown[group].amount}
              </Typography>
              <Typography variant="subtitle1">
                {user["https://poip.io/roles"][0] === "BUYER"
                  ? "Your Impact"
                  : "Impact Delivered"}
              </Typography>
              <div style={{ height: "15px" }} />
              <Typography variant="subtitle2">
                {user["https://poip.io/roles"][0] === "BUYER"
                  ? "You Funded"
                  : "Total Funds Generated"}
                : ${sdgGroupBreakdown[group].value}
              </Typography>
            </div>
          </SmallFixedHeightPaper>
        </Grid>
      ))}
    </Grid>
  );
}

export default TypeTotals;
