import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import { PURCHASEBYID } from "../../utilities/queries";
import Report from "./Report";

function Purchase({ client, ...props }) {
  const { loading, error, data } = useQuery(PURCHASEBYID, {
    variables: {
      id: parseInt(props.match.params.purchaseId)
    },
    client
  });

  if (loading && !data) return <Loading />;
  if (error) return <ErrorView error={error} />;
  if (!data || !data.purchaseById) return <ErrorView />;

  return <Report purchases={[data.purchaseById]} />;
}

export default withApollo(Purchase);
