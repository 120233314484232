// takes in a js File object of an image and returns a resized jpg file
// if the input image is lower resolution, then it won't resize
async function resizeImageToFile(file, newWidth = 800, newHeight = 800) {
  let successCallback;
  let failedCallback;

  const result = new Promise((resolve, reject) => {
    successCallback = resolve;
    failedCallback = reject;
  });

  try {
    const fileReader = new FileReader();

    fileReader.onload = function(event) {
      try {
        const image = new Image();

        image.onload = function() {
          try {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            // calc sizes, maintaining aspect ratio
            if (image.width <= newWidth && image.height <= newHeight) {
              canvas.width = image.width;
              canvas.height = image.height;
            } else if (image.width > image.height) {
              canvas.width = newWidth;
              canvas.height = (image.height * newWidth) / image.width;
            } else {
              canvas.height = newHeight;
              canvas.width = (image.width * newHeight) / image.height;
            }
            context.drawImage(
              image,
              0,
              0,
              image.width,
              image.height,
              0,
              0,
              canvas.width,
              canvas.height
            );

            canvas.toBlob(successCallback, "image/jpeg");
          } catch (error) {
            failedCallback(error);
          }
        };

        image.src = event.target.result;
      } catch (error) {
        failedCallback(error);
      }
    };

    fileReader.readAsDataURL(file);
  } catch (error) {
    failedCallback(error);
  }

  return result;
}

export default resizeImageToFile;
