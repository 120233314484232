import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PNSemiBold } from "./shared/StyledComponents";
import POISocial from "./shared/POISocial";
import EmailForm from "./shared/EmailForm";

const FooterContainer = styled.div`
  display: grid;
  grid-row-gap: 1em;
  background-color: #232329;
  color: white;
  padding: 4em 2em;

  img {
    grid-area: logo;
    width: 16em;
  }
  @media only screen and (min-width: 770px) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    padding: 4em 0em;
    img {
      width: 22em;
    }
  }
  a {
    color: white;
  }
  .email-footer {
    width: 100%;
    max-width: 304px;
    @media only screen and (min-width: 770px) {
      width: 80%;
    }
  }
`;

const GetToKnowUs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 2em;
  grid-column: 3/8;
  grid-row-gap: 0.5em;
  height: fit-content;
  align-self: end;
  @media only screen and (min-width: 770px) {
    grid-column: 5/8;
  }

  div > a {
    color: white;
    h6:hover {
      opacity: 60%;
    }
  }
`;

const JoinUs = styled.div`
  align-self: center;
  grid-column: 3/7;
  @media only screen and (min-width: 770px) {
    grid-column: 2/4;
  }

  img {
    padding-bottom: 1rem;
  }
`;

const SocialContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  grid-column: 1;
`;
function Footer() {
  const linkData = {
    "/about": "About",
    "/howitworks": "How it works",
    "/corporate": "Corporate",
    "/contact": "Contact",
    "https://medium.com/proofofimpact": "Blog",
    "/terms": "Terms and Conditions",
    "/privacy": "Privacy Policy"
  };

  const links = Object.keys(linkData).map((link, i) => {
    const linkText = <PNSemiBold variant="h6">{linkData[link]}</PNSemiBold>;
    return (
      <div key={i}>
        {link.charAt(0) === "/" ? (
          <Link to={link}>{linkText}</Link>
        ) : (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        )}
      </div>
    );
  });
  return (
    <FooterContainer>
      <JoinUs>
        <img src={require("../assets/images/poi-logo.svg")} alt="logo"></img>
        <Typography variant="h6">Radically redefining impact.</Typography>
        <div className="email-footer">
          <EmailForm
            buttonText="Submit"
            buttonWidth="100%"
            outlined
            noLabel
            hidden
            noFixedButton
            footer
          />
        </div>
      </JoinUs>

      <GetToKnowUs>
        <PNSemiBold variant="h5" style={{ gridColumn: "1/-1" }}>
          Get to know us
        </PNSemiBold>
        {links}
        <PNSemiBold variant="h6" style={{ gridColumn: "1/-1" }}>
          {`© Proof of Impact ${new Date().getFullYear()}`}
        </PNSemiBold>
        <SocialContainer>
          <POISocial color={"white"}></POISocial>
        </SocialContainer>
      </GetToKnowUs>
    </FooterContainer>
  );
}

export default Footer;
