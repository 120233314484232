import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Markers,
  ZoomableGroup
} from "react-simple-maps";

import world50m from "../../assets/world50m.json";

const FixedHeightPaper = styled(Paper)`
  height: 400px;
  background-color: #e0dcd5;
`;

function MissionLocation({ location: { longitude, latitude }, markerColor }) {
  return (
    <FixedHeightPaper>
      <div
        style={{
          height: "70px",
          width: "100%",
          borderBottom: "solid 1px #16161a"
        }}
      >
        <div style={{ height: "20px" }} />
        <Typography variant="body1" style={{ marginLeft: "30px" }}>
          Last Mission Location
        </Typography>
      </div>
      <ComposableMap
        projectionConfig={{ scale: 110 }}
        height={300}
        style={{
          width: "100%"
        }}
      >
        <ZoomableGroup center={[0, 20]} disablePanning>
          <Geographies geography={world50m}>
            {(geographies, projection) =>
              geographies.map((geography, i) => (
                <Geography
                  key={i}
                  geography={geography}
                  projection={projection}
                  style={{
                    default: {
                      fill: "#7cabe0",
                      stroke: "#e0dcd5"
                    },
                    hover: {
                      fill: "#7cabe0",
                      stroke: "#e0dcd5"
                    },
                    pressed: {
                      fill: "#7cabe0",
                      stroke: "#e0dcd5"
                    }
                  }}
                />
              ))
            }
          </Geographies>
          {longitude && latitude && (
            <Markers>
              <Marker
                marker={{
                  coordinates: [longitude, latitude]
                }}
              >
                <circle cx="3" cy="3" r="6" fill={markerColor} />
              </Marker>
            </Markers>
          )}
        </ZoomableGroup>
      </ComposableMap>
    </FixedHeightPaper>
  );
}

export default MissionLocation;
