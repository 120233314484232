import React from "react";
import { hydrate, render } from "react-dom";
import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";
import { AppRoot } from './components/AppRoot';

process.env.NODE_ENV !== "development" &&
  Sentry.init({
    dsn: "https://f9cf906e6ae54f74982facee4abec52e@sentry.io/1873064"
  });

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<AppRoot />, rootElement);
} else {
  render(<AppRoot />, rootElement);
}

serviceWorker.unregister();
