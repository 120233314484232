import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(1)
  }
}));

function Confirmation({ details, done, ...props }) {
  const { button } = useStyles();
  return (
    <>
      <br />
      <Grid item>
        <Typography variant="h5">
          The event was submitted and will be reviewed
        </Typography>
        <div className={`circle-loader ${done ? "load-complete" : ""}`}>
          <div
            className="checkmark draw"
            style={{ display: done ? "block" : "none" }}
          />
        </div>
      </Grid>
      <Grid item>
        <Button
          className={button}
          onClick={() => props.history.push("/events/dashboard")}
        >
          Impact Event Panel
        </Button>
        <Button className={button} onClick={() => window.location.reload()}>
          {`Submit Another ${details.eventName} Event`}
        </Button>
      </Grid>
    </>
  );
}

export default Confirmation;
