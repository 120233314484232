import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ErrorView from "../../components/ErrorView";

const useStyles = makeStyles(theme => ({
  button: {
    // background: theme.palette.primary.main,
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    width: "40%",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(1)
  }
}));

function Submitting({ errors, loading, ...props }) {
  const { button } = useStyles();
  const { submitError } = errors ? errors : {};
  return (
    <>
      {submitError && (
        <>
          <Grid container direction="column" justify="center">
            <ErrorView error={{ message: submitError.message }} />
            <Grid item>
              <Typography variant="h6">
                Please review and edit your event in the Impact Event Panel.
              </Typography>
              <Button
                className={button}
                onClick={() => props.history.push("/events/dashboard")}
              >
                Impact Event Panel
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <br />
      <br />
      {loading && (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h5">Verification in progress...</Typography>
          </Grid>
          <Grid item>
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Grid>
        </Grid>
      )}
      <br />
    </>
  );
}

export default Submitting;
