import React from "react";
import { withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import GraphImg from "graphcms-image";
import { ABOUT } from "../../utilities/queries";
import {
  AboutContainer,
  SplitPanel,
  MissionVision,
  MainContentContainer
} from "./AboutStyles";
import {
  PNSemiBold,
  PNRegular,
  Banner,
  GridGap,
  BannerGraphImg,
  InconsolataBold
} from "../shared/StyledComponents";
import GettingStarted from "../shared/GettingStarted";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

function About({ client }) {
  const { loading, data, error } = useQuery(ABOUT, { client });
  if (loading && !data) return <Loading />;
  if (error) {
    return <ErrorView error={error}></ErrorView>;
  }

  const {
    header,
    headerTextColor,
    headerBanner,
    aboutUs,
    aboutUsImage,
    mission,
    vision,
    ourApproach,
    ourApproachImage,
    mainContentImage,
    impactMarketplace,
    funders,
    impactCreators,
    verifiedImpact
  } = data.abouts[0];
  return (
    <AboutContainer>
      <Banner>
        <div className="banner-text">
          <Typography variant="h1" style={{ color: headerTextColor.hex }}>
            {header}
          </Typography>
        </div>
        <BannerGraphImg image={headerBanner} />
      </Banner>

      <SplitPanel gridArea={"aboutus"}>
        <div className="content">
          <Typography variant="h1">About Us</Typography>
          <PNRegular variant="h5">{aboutUs}</PNRegular>
        </div>
        <GraphImg image={aboutUsImage}></GraphImg>
      </SplitPanel>

      <MissionVision>
        <div>
          <Typography variant="h3">Mission</Typography>
          <PNRegular variant="h5">{mission}</PNRegular>
        </div>
        <div>
          <Typography variant="h3">Vision</Typography>
          <PNRegular variant="h5">{vision}</PNRegular>
        </div>
      </MissionVision>

      <MainContentContainer>
        <GraphImg image={mainContentImage}></GraphImg>
        <div className="one-two-column">
          <Grid container direction="column">
            <Typography variant="h3">Impact Marketplace</Typography>
            <PNSemiBold variant="body1">{impactMarketplace}</PNSemiBold>
          </Grid>
          <div className="right-content">
            <div>
              <Typography variant="h5">Creators</Typography>
              <PNSemiBold variant="body1">{impactCreators}</PNSemiBold>
            </div>
            <div>
              <Typography variant="h5">Funders</Typography>
              <PNSemiBold variant="body1">{funders}</PNSemiBold>
            </div>
            <div className="bottom-row">
              <Typography variant="h5">Verified Impact</Typography>
              <PNSemiBold variant="body1">{verifiedImpact}</PNSemiBold>
            </div>
          </div>
        </div>
      </MainContentContainer>

      <SplitPanel gridArea={"approach"}>
        <GraphImg image={ourApproachImage}></GraphImg>
        <div className="content">
          <Typography variant="h1">Our Approach</Typography>
          <GridGap>
            <PNRegular variant="h5">{ourApproach}</PNRegular>
            <button>
              <Link to="/howitworks">
                <InconsolataBold variant="subtitle2">
                  Learn More
                </InconsolataBold>
              </Link>
            </button>
          </GridGap>
        </div>
      </SplitPanel>

      <GettingStarted></GettingStarted>
    </AboutContainer>
  );
}

export default withApollo(About);
