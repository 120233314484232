import gql from "graphql-tag";
// Fragments for reuse:
const GCMS_IMAGE = gql`
  fragment gcmsImage on Asset {
    handle
    height
    width
    url
  }
`;
const EVENT_PAGE = gql`
  fragment eventPageDetails on EventPage {
    missionName
    isActive
    displayImage {
      ...gcmsImage
    }
    displayDescription
    route
    impactCategory
    sdgs {
      name
      number
      color {
        hex
      }
      icon {
        ...gcmsImage
      }
      subGoals
      category
    }
    eventPageMissionStart {
      id
      description
      header
      backgroundImage {
        ...gcmsImage
      }
    }
    eventPageMissionSteps {
      header
      description
      eventPagePopovers {
        header
        description
        locationPercentFromTop
        locationPercentFromLeft
        textColor {
          hex
        }
        textBackground {
          hex
        }
      }
      backgroundImage {
        ...gcmsImage
      }
      textColor {
        css
        hex
      }
      textBackground {
        css
        rgba {
          r
          g
          b
          a
        }
      }
      order
    }
    eventPageMissionSummary {
      header
      stats
      backgroundImages {
        ...gcmsImage
      }
    }
    eventPageMissionFinal {
      header
      description
      sdgSubGoals
      backgroundImage {
        ...gcmsImage
      }
    }
    eventPageFundingForm {
      header
      description
      unit
      fundingOptions
      sellerHomepage
      sampleReport {
        ...gcmsImage
        url
      }
    }
  }
  ${GCMS_IMAGE}
`;

const MARKETPLACE_EVENT_DETAILS = gql`
  fragment marketplaceEvent on EventPage {
    uuid
    route
    sdgs {
      number
    }
    displayImage {
      ...gcmsImage
    }
    displayDescription
    missionName
    impactCategory
  }
  ${GCMS_IMAGE}
`;

const TOKEN_MAIN = gql`
  fragment tokenMain on Token {
    id
    address
    name
    symbol
    decimals
    description
    sdgs
    uuid
    route
  }
`;

const USER = gql`
  query user($username: String, $authId: String) {
    user(user: { username: $username, authId: $authId }) {
      id
      authId
      username
      profileData {
        public
        name
        organization
        website
        phoneNumber
      }
      tokens {
        ...tokenMain
        eventPage {
          ...eventPageDetails
        }
      }
    }
  }
  ${TOKEN_MAIN}
  ${EVENT_PAGE}
`;

const USERS = gql`
  {
    users {
      authId
      username
      profileData {
        public
        name
        organization
        website
        phoneNumber
      }
    }
  }
`;

const TOKEN = gql`
  query token($address: String, $route: String, $id: Int, $uuid: String) {
    token(token: { address: $address, route: $route, id: $id, uuid: $uuid }) {
      ...tokenMain
    }
  }
  ${TOKEN_MAIN}
`;

const TOKENWITHMISSION = gql`
  query token($address: String, $route: String, $id: Int, $uuid: String) {
    token(token: { address: $address, route: $route, id: $id, uuid: $uuid }) {
      ...tokenMain
      eventPage {
        ...eventPageDetails
      }
    }
  }
  ${EVENT_PAGE}
  ${TOKEN_MAIN}
`;

const TOKENS = gql`
  {
    tokens {
      ...tokenMain
      eventPage {
        ...eventPageDetails
      }
    }
  }
  ${EVENT_PAGE}
  ${TOKEN_MAIN}
`;

const EVENTPAGE = gql`
  query eventPage($uuid: String, $route: String) {
    eventPage(where: { uuid: $uuid, route: $route }) {
      ...eventPageDetails
    }
  }
  ${EVENT_PAGE}
`;

const COMMITMENTS = gql`
  query commitments(
    $tokenAddress: String
    $route: String
    $seller: String
    $buyer: String
  ) {
    commitments(
      token: { address: $tokenAddress, route: $route }
      buyer: { username: $buyer }
      seller: { username: $seller }
    ) {
      amount
      remaining
      price
      timestamp
      token {
        id
        uuid
        address
        sdgs
        name
      }
      seller {
        authId
        username
      }
      buyer {
        authId
        username
      }
    }
  }
`;

const EVENTS = gql`
  query events($tokenAddress: String, $route: String, $seller: String) {
    events(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
    ) {
      id
      amount
      timestamp
      submitted
      approved
      latitude
      longitude
      transactionHash
      ipfsHash
      metadata
      variantId
      productVariant {
        availableForSale
        displayName
        inventoryQuantity
        price
        selectedOptions {
          name
          value
        }
        storefrontId
        title
      }
      token {
        id
        uuid
        name
        address
        sdgs
        route
      }
      seller {
        id
        authId
        username
      }
    }
  }
`;

const EVENT = gql`
  query event($variantId: String) {
    event(event: { variantId: $variantId }) {
      id
      amount
      timestamp
      submitted
      approved
      latitude
      longitude
      transactionHash
      ipfsHash
      metadata
      variantId
      productVariant {
        availableForSale
        displayName
        inventoryQuantity
        price
        selectedOptions {
          name
          value
        }
        storefrontId
        title
      }
      token {
        id
        uuid
        name
        address
        sdgs
        route
      }
      seller {
        id
        authId
        username
      }
    }
  }
`;

const PURCHASES = gql`
  query purchases(
    $tokenAddress: String
    $route: String
    $seller: String
    $buyer: String
  ) {
    purchases(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
      buyer: { username: $buyer }
    ) {
      id
      amount
      price
      timestamp
      sellerPaid
      rangeStart
      rangeEnd
      orderId
      buyer {
        id
        authId
        username
      }
      event {
        id
        seller {
          id
          username
        }
        token {
          id
          name
          address
          sdgs
          route
        }
        latitude
        longitude
        metadata
        timestamp
        submitted
        amount
      }
    }
  }
`;

const PURCHASESBYORDER = gql`
  query purchasesByOrder($orderId: String) {
    purchasesByOrder(orderId: $orderId) {
      id
      amount
      price
      timestamp
      sellerPaid
      rangeStart
      rangeEnd
      orderId
      buyer {
        id
        authId
        username
      }
      event {
        id
        seller {
          id
          username
        }
        token {
          id
          name
          address
          sdgs
          route
        }
        latitude
        longitude
        metadata
        timestamp
        submitted
        amount
      }
    }
  }
`;

const PURCHASEBYID = gql`
  query purchaseById($id: Int) {
    purchaseById(id: $id) {
      id
      amount
      price
      timestamp
      sellerPaid
      rangeStart
      rangeEnd
      orderId
      buyer {
        id
        authId
        username
      }
      event {
        id
        seller {
          id
          username
        }
        token {
          id
          name
          address
          sdgs
          route
        }
        latitude
        longitude
        metadata
        timestamp
        submitted
        amount
      }
    }
  }
`;

const TOKENBALANCES = gql`
  query tokenBalances(
    $tokenAddress: String
    $route: String
    $username: String
    $name: String
  ) {
    tokenBalances(
      token: { address: $tokenAddress, route: $route, name: $name }
      user: { username: $username }
    ) {
      balance
      token {
        id
        uuid
        name
      }
      user {
        authId
      }
    }
  }
`;

const COMMITBALANCES = gql`
  query commitBalances(
    $tokenAddress: String
    $route: String
    $seller: String
    $buyer: String
  ) {
    commitBalances(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
      buyer: { username: $buyer }
    ) {
      balance
      token {
        id
        uuid
        name
        address
      }
      seller {
        authId
        username
      }
      buyer {
        authId
      }
    }
  }
`;

const PRICES = gql`
  query prices($tokenAddress: String, $route: String, $seller: String) {
    prices(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
    ) {
      price
      token {
        id
        uuid
        name
        address
        sdgs
      }
      seller {
        authId
        username
        profileData {
          name
          organization
        }
      }
    }
  }
`;

const COMMITDRAWDOWNS = gql`
  query commitDrawdowns(
    $tokenAddress: String
    $route: String
    $seller: String
    $buyer: String
  ) {
    commitDrawdowns(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
      buyer: { username: $buyer }
    ) {
      id
      amount
      timestamp
      sellerPaid
      event {
        id
        ipfsHash
        token {
          name
          sdgs
        }
        latitude
        longitude
      }
      commitment {
        amount
        remaining
        price
        timestamp
        token {
          uuid
          address
          name
        }
        buyer {
          username
        }
        seller {
          username
        }
      }
    }
  }
`;

const SUBMITTEDEVENTS = gql`
  query submittedEvents(
    $tokenAddress: String
    $route: String
    $seller: String
    $limit: Int
  ) {
    submittedEvents(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
      limit: $limit
    ) {
      id
      amount
      timestamp
      submitted
      latitude
      longitude
      metadata
      status
      verificationDoc
      token {
        id
        uuid
        address
        name
        sdgs
        route
      }
      seller {
        authId
        username
        profileData {
          name
        }
      }
      event {
        id
        ipfsHash
      }
    }
  }
`;

const SUBMITTEDEVENTS_WITH_PAGINATION = gql`
  query submittedEventsWithPagination(
    $tokenAddress: String
    $route: String
    $seller: String
    $limit: Int
    $offset: Int
    $orderBy: OrderByInput
  ) {
    submittedEventsWithPagination(
      token: { address: $tokenAddress, route: $route }
      seller: { username: $seller }
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      total
      totalApproved
      totalWorking
      totalDenied
      totalPending
      events {
        id
        amount
        timestamp
        submitted
        latitude
        longitude
        metadata
        status
        verificationDoc
        token {
          id
          uuid
          address
          name
          sdgs
          route
        }
        seller {
          authId
          username
          profileData {
            name
          }
        }
        event {
          id
          ipfsHash
        }
      }
    }
  }
`;

const INPUTAPP = gql`
  query inputApps($tokenId: String, $sellerId: String) {
    inputApps(tokenId: $tokenId, sellerId: $sellerId) {
      uuid
      sellerId
      tokenId
      schema
    }
  }
`;

const ORDERS = gql`
  query orders($orderCursor: String) {
    orders(
      query: "attribution_app_id:current fulfillment_status:unfulfilled"
      after: $orderCursor
      first: 25
      reverse: true
    ) {
      edges {
        node {
          id
          processedAt
          name
          capturable
          totalCapturableSet {
            presentmentMoney {
              amount
            }
          }
          presentmentCurrencyCode
          customAttributes {
            key
            value
          }
          lineItems(first: 10) {
            edges {
              node {
                title
                variantTitle
                quantity
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      processedAt
      name
      capturable
      totalCapturableSet {
        presentmentMoney {
          amount
        }
      }
      presentmentCurrencyCode
      customAttributes {
        key
        value
      }
      lineItems(first: 10) {
        edges {
          node {
            title
            variantTitle
            quantity
            originalTotalSet {
              presentmentMoney {
                amount
              }
            }
            variant {
              id
            }
          }
        }
      }
    }
  }
`;

const ABOUT = gql`
  query {
    abouts(first: 1) {
      header
      headerTextColor {
        hex
      }
      headerBanner {
        ...gcmsImage
      }
      aboutUs
      aboutUsImage {
        ...gcmsImage
      }
      mission
      vision
      ourApproach
      ourApproachImage {
        ...gcmsImage
      }
      mainContentImage {
        ...gcmsImage
      }
      impactMarketplace
      impactCreators
      funders
      verifiedImpact
    }
  }
  ${GCMS_IMAGE}
`;

const CORPORATE = gql`
  query {
    corporates(first: 1) {
      header
      headerTextColor {
        hex
      }
      headerBanner {
        ...gcmsImage
      }
      bodyHeader
      bodyContent {
        markdown
        html
      }
    }
  }
  ${GCMS_IMAGE}
`;
const MARKETPLACE = gql`
  query {
    marketplaces(first: 1) {
      mainHeader
      mainDescription
      headerTextColor {
        hex
      }
      howItWorksHeader
      howItWorksDescription {
        markdown
      }
      mainEvents {
        ...marketplaceEvent
      }
      featuredHeader
      featuredDescription
      featuredEvent {
        ...marketplaceEvent
      }
      comingSoonEvents {
        ...marketplaceEvent
      }
      shareHeader
      shareMessage
      heroBanner {
        ...gcmsImage
      }
      impactBackground {
        ...gcmsImage
      }
      sdgBackground {
        ...gcmsImage
      }
    }
  }
  ${GCMS_IMAGE}
  ${MARKETPLACE_EVENT_DETAILS}
`;

const ACTIVEEVENTS = gql`
  query {
    marketplaces(first: 1) {
      mainEvents {
        ...marketplaceEvent
      }
    }
  }
  ${MARKETPLACE_EVENT_DETAILS}
`;

const HOWITWORKS = gql`
  query {
    howItWorkses(first: 1) {
      header
      headerTextColor {
        hex
      }
      headerBanner {
        ...gcmsImage
      }
      howItWorksSteps
      howItWorksIcons {
        ...gcmsImage
        fileName
      }
      whatIsImpact
      provingImpact
      ourMarketplace
      howItWorksImage {
        ...gcmsImage
      }
    }
  }
  ${GCMS_IMAGE}
`;

const POLICIES = gql`
  query {
    policies(first: 1) {
      termsAndConditions
      privacyPolicy
      cookiePolicy
    }
  }
`;

export {
  USER,
  USERS,
  TOKEN,
  TOKENWITHMISSION,
  TOKENS,
  PRICES,
  TOKENBALANCES,
  COMMITBALANCES,
  COMMITMENTS,
  EVENTS,
  EVENT,
  EVENTPAGE,
  INPUTAPP,
  PURCHASES,
  COMMITDRAWDOWNS,
  SUBMITTEDEVENTS,
  SUBMITTEDEVENTS_WITH_PAGINATION,
  ORDERS,
  MARKETPLACE,
  ABOUT,
  HOWITWORKS,
  CORPORATE,
  PURCHASESBYORDER,
  PURCHASEBYID,
  ORDER,
  POLICIES,
  ACTIVEEVENTS
};
