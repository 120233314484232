import React from "react";
import { Typography, Grid } from "@material-ui/core";

function Review({ values }) {
  return (
    <Grid
      container
      style={{ textAlign: "left", marginLeft: "15%" }}
      spacing={2}
    >
      <Grid item>
        {Object.keys(values).map((header, i) => {
          // handle geolocation objects
          if (header.toLowerCase().includes("geolocation")) {
            return (
              <Typography variant="h6" style={{ fontWeight: "bold" }} key={i}>
                {`${camelToTitle(header)}: `}
                <Typography variant="body1">
                  {`${values[header].latitude}, ${values[header].longitude}`}
                </Typography>
              </Typography>
            );
          }
          // handle images
          if (header.toLowerCase().includes("photo")) {
            return (
              <Grid item key={i}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {`${camelToTitle(header)}: `}
                </Typography>
                <br />
                {values[header].map((imgHash, i) => (
                  <img
                    key={i}
                    src={`https://ipfs.poip.io/ipfs/${imgHash}`}
                    alt=""
                    width="100"
                    height="100"
                    className="d-block"
                    style={{ marginRight: "10px" }}
                  />
                ))}
              </Grid>
            );
          }
          // handle multifield objects
          if (typeof values[header] === "object") {
            const multifield = values[header];
            return Object.keys(multifield).map((key, i2) => {
              return (
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold" }}
                  key={`${i}-${i2}`}
                >
                  {`${camelToTitle(key)}: `}
                  <Typography variant="body1">{`${multifield[key]}`}</Typography>
                </Typography>
              );
            });
          }

          return (
            <Typography variant="h6" style={{ fontWeight: "bold" }} key={i}>
              {`${camelToTitle(header)}: `}
              <Typography variant="body1"> {values[header]}</Typography>
            </Typography>
          );
        })}
        {/* {Object.keys(images).map((imageName, idx) => {
          const imageArray = images[imageName];
          return (
            <Grid item key={idx}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {`${camelToTitle(imageName)}: `}
              </Typography>
              <br />
              {imageArray.map((img, i) => (
                <img
                  key={i}
                  src={img}
                  alt=""
                  width="100"
                  height="100"
                  className="d-block"
                  style={{ marginRight: "10px" }}
                />
              ))}
            </Grid>
          );
        })} */}
      </Grid>
    </Grid>
  );
}

const camelToTitle = camel => {
  return camel
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase());
};
export default Review;
