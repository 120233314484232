import React, { useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useAuth0 } from "../Auth0Provider";

const FixedHeightPaper = styled(Paper)`
  height: 400px;
  background-color: #e0dcd5;
`;

const BlackWhiteButton = styled(Button)`
  color: ${props => (props.selected ? "black" : "white")};
  background-color: ${props => (!props.selected ? "black" : "transparent")};
`;

const colors = ["#4371cc", "#de896f", "#106640", "#e8aa46"];

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

function Graphs({ pieData }) {
  const { user } = useAuth0();
  const width = useWidth();
  const [units, setUnits] = useState("value");
  return (
    <FixedHeightPaper>
      <div
        style={{
          height: "70px",
          width: "100%",
          borderBottom: "solid 1px #16161a"
        }}
      >
        <div style={{ height: "20px" }} />
        <Typography variant="body1" style={{ marginLeft: "30px" }}>
          {user["https://poip.io/roles"][0] === "BUYER"
            ? "Your Portfolio"
            : "Your Sold Impact"}
        </Typography>
      </div>
      <div
        style={{
          marginTop: "10px",
          marginLeft: "10px",
          marginRight: "10px",
          display: "flex",
          flexWrap: "nowrap"
        }}
      >
        <Typography>Breakdown By Impact</Typography>
        <ButtonGroup
          size="small"
          variant="outlined"
          style={{ marginLeft: "auto" }}
        >
          <BlackWhiteButton
            size="small"
            selected={units === "value"}
            onClick={() => setUnits("value")}
          >
            Dollars
          </BlackWhiteButton>
          <BlackWhiteButton
            size="small"
            selected={units !== "value"}
            onClick={() => setUnits("amount")}
          >
            Impact
          </BlackWhiteButton>
        </ButtonGroup>
      </div>
      <div style={{ margin: "10px", alignContent: "center" }}>
        <PieChart width={width === "md" ? 370 : 500} height={300}>
          <Pie
            dataKey={units}
            data={pieData}
            innerRadius={width === "md" ? 60 : 80}
            outerRadius={width === "md" ? 80 : 100}
            fill="#ffffff"
            animationBegin={0}
            animationDuration={500}
          >
            {pieData.map((entry, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip
            formatter={value => (units === "value" ? `$${value}` : value)}
          />
          <Legend
            layout="vertical"
            verticalAlign="top"
            align="left"
            iconSize="10"
            formatter={value => `${value.slice(0, 15)}...`}
          />
        </PieChart>
      </div>
    </FixedHeightPaper>
  );
}

export default Graphs;
