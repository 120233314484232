import React from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

import SocialShare from "../shared/SocialShare";
import { Divider, PNBold } from "../shared/StyledComponents";
import EmailForm from "../shared/EmailForm";
import { useAuth0 } from "../Auth0Provider";

function MissionSignUpForm(props) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const { loginWithRedirect } = useAuth0();
  const { header, description } = props.event;
  const { color, category } = props.sdgs[0];
  return (
    <div style={{
      marginTop: '3rem',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'space-between'
    }}>
        <div>
          <Divider color={color.hex} height={"4px"} />
          <Grid container justify="space-between">
            <PNBold variant="h6" style={{ color: color.hex }}>
              {category}
            </PNBold>
            <SocialShare
              color={color.hex}
              header={header}
              description={description}
            ></SocialShare>
          </Grid>
          <Typography variant="h2" style={{ fontWeight: "bold" }}>
            {header}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </div>
        <EmailForm buttonText="Sign up" tags={["B2C", "sign-up-mission"]}/>
        {process.env.REACT_APP_ALLOW_DONATIONS === "true" && (
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={
              isDesktop
                ? { position: "absolute", bottom: "4rem" }
                : { paddingBottom: "3.5rem" }
            }
          >
            <Typography variant="h6">Already have an account?</Typography>
            <Link
              to="#"
              onClick={() =>
                loginWithRedirect({ appState: { targetPath: "/" } })
              }
            >
              <Typography variant="h6">Log in</Typography>
            </Link>
          </Grid>
        )}
      </div>
  );
}

export default MissionSignUpForm;
