import React from "react";
import GraphImg from "graphcms-image";

import { Inconsolata, PNBold } from "../shared/StyledComponents";
import {
  MissionStartContainer,
  MissionStartCard,
  PositionedArrow
} from "./MissionStyles";

function MissionStart(props) {
  const { activeEvent } = props;
  const { backgroundImage, header, description } = props.data;

  return (
    <MissionStartContainer>
      <MissionStartCard>
        <div className={"card"}>
          <div className={'content-wrapper'}>
            <div className={'details'}>
              <span className="mission-card-header">
                <PNBold variant="h6">{header}</PNBold>
              </span>
              <div className={`card-text lines`}><Inconsolata variant="subtitle2">{description}</Inconsolata></div>
            </div>
            <div className={'side-header'}>
              <header>
                <PNBold variant="h6">Impact Mission</PNBold>
              </header>
            </div>
          </div>
        </div>
      </MissionStartCard>

      {activeEvent ? (
        <>
          <PositionedArrow>
            <div className="arrow bounce">
              <img
                src={require("../../assets/images/arrow.svg")}
                alt="arrow"
              ></img>
            </div>
          </PositionedArrow>
        </>
      ) : null}

      <div style={{ gridColumn: "1/-1", gridRow: "1/-1" }}>
        <GraphImg
          image={backgroundImage}
          style={{ height: "100vh" }}
        ></GraphImg>
      </div>
    </MissionStartContainer>
  );
}

export default MissionStart;
