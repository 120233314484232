import styled from "styled-components";
import { OneTwoColumn } from "../shared/StyledComponents";

const HowItWorksContainer = styled.div`
  display: grid;
  grid-template-areas:
    "hero"
    "gettingstarted"
    "howitworks"
    "quicksheet"
    "footer";
`;

const HowItWorksContent = styled.div`
  grid-area: howitworks;
  background: ${props => props.theme.palette.secondary.main};
  display: grid;
  grid-template-columns: auto;
  text-align: center;
  grid-row-gap: 2rem;
  padding: 3rem 2rem;

  @media only screen and (min-width: 770px) {
    padding: 5rem 14rem;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 3rem;
    text-align: left;
  }

  .step {
    display: grid;
    grid-template-columns: 1fr;

    .icon-left {
    }
    @media only screen and (min-width: 770px) {
      grid-column: 1/-1;
      > div:first-child {
        display: grid;
        grid-template-columns: 1fr 40% 1fr;
        grid-column-gap: 2em;
      }

      .icon-left {
        margin: 0 auto;
        > div:nth-child(2) {
          display: grid;
          grid-gap: 1em;
          height: fit-content;
        }
      }

      .icon-right {
        > div:first-child {
          display: grid;
          grid-gap: 1em;
          grid-column: 2;
          height: fit-content;
        }
      }

      .content {
        align-self: center;
      }
    }
  }
`;

const Quicksheet = styled(OneTwoColumn)`
  grid-area: quicksheet;
  text-align: center;

  .right-content {
    div {
      align-items: center;
      width: 100%;
    }
    > div:nth-child(2) {
      align-items: start;
      height: fit-content;
    }
  }
`;

export { HowItWorksContainer, HowItWorksContent, Quicksheet };
