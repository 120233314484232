import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    width: "15%",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(1)
  }
}));

function SaveAndSkipButtons({ handlers, page, title, component, ...props }) {
  const { button } = useStyles();
  const { navigateAndClear, saveForLater, setPage, submitEvent } = handlers;
  const [showPanelButton, setShowPanelButton] = useState(false);
  const handleSubmit = async () => {
    const {
      createEvent: { id }
    } = await saveForLater();
    setPage(page + 1);
    submitEvent({ variables: { id } });
  };
  const saveAndContinue = () => {
    saveForLater();
    navigateAndClear();
  };

  return (
    <>
      {showPanelButton && (
        <Button
          className={button}
          style={{ width: "50%" }}
          onClick={() => props.history.push("/events/dashboard")}
        >
          Impact Events Panel
        </Button>
      )}
      {!["GeolocationInput", "DateTimeInput"].includes(component) ? (
        <Button
          className={button}
          style={{ width: "50%" }}
          onClick={() => {
            if (component === "Review") {
              setShowPanelButton(true);
              return saveForLater();
            }
            return saveAndContinue();
          }}
        >
          {component === "Review"
            ? "Save for Later"
            : `Save ${title} & Continue`}
        </Button>
      ) : null}

      <Grid container item justify="center" wrap="nowrap">
        <Button className={button} onClick={() => setPage(page - 1)}>
          Back
        </Button>
        <Button className={button} onClick={() => window.location.reload()}>
          Cancel
        </Button>
        {component !== "Review" ? (
          <Button className={button} onClick={() => navigateAndClear()}>
            Skip
          </Button>
        ) : (
          <Button className={button} onClick={() => handleSubmit()}>
            Submit
          </Button>
        )}
      </Grid>
    </>
  );
}

export default SaveAndSkipButtons;
