import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import GraphImg from "graphcms-image";

import MissionDiscovery from "./MissionDiscovery";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import {
  MissionFinalGrid,
  SDGIcon,
  // CommunityImpact,
  GlobalContribution,
  DiscoverPanel,
  IconTextContainer
} from "./MissionStyles";
import { PNBold, CTAButton } from "../shared/StyledComponents";
import {
  PRICES,
  SUBMITTEDEVENTS,
  USERS,
  TOKENBALANCES
} from "../../utilities/queries";

function MissionFinal({ data, sdgs, route, signupMission, client }) {
  const { header, sdgSubGoals, description, backgroundImage } = data;
  const pricesReturn = useQuery(PRICES, { client });
  const eventsReturn = useQuery(SUBMITTEDEVENTS, { client });
  const usersReturn = useQuery(USERS, { client });
  const tokenBalancesReturn = useQuery(TOKENBALANCES, {
    client,
    variables: {
      route
    }
  });
  const queries = [
    pricesReturn,
    eventsReturn,
    usersReturn,
    tokenBalancesReturn
  ];
  if (queries.some(q => q.error))
    return <ErrorView error={queries.find(q => q.error).error} />;
  if (queries.some(q => q.loading && !q.data)) return <Loading />;
  const subgoals = sdgSubGoals.reduce((subgoals, current) => {
    current.toLowerCase();
    const [goal] = current.split(".");
    const matchingSdg = sdgs.find(sdg => sdg.number === parseInt(goal));
    const currentSubgoal = matchingSdg ? matchingSdg.subGoals[current] : null;
    subgoals[goal] = { ...subgoals[goal], [current]: currentSubgoal };
    return subgoals;
  }, {});
  // let totalMinted = 0;
  // let impactByEvent = 0;
  // eventsReturn.data.submittedEvents.forEach(event => {
  //   if (event.status === "APPROVED") {
  //     totalMinted += event.amount;
  //   }
  // });
  // tokenBalancesReturn.data.tokenBalances.forEach(balance => {
  //   impactByEvent += balance.balance;
  // });
  // const numOfUsers = usersReturn.data.users.length;
  return (
    <>
      <MissionFinalGrid>
        {/* <CommunityImpact>
          <div>
            <PNBold variant="h5" style={{ fontWeight: "bold" }}>
              Community Impact
            </PNBold>
            <Typography variant="h6">
              {signupMission
                ? `Welcome! Your contribution to this mission enables you to stay connected. Next, consider funding a mission to contribute to our goal of reaching 1,000,000 impact. `
                : `Your contribution to this mission contributes to our community goal
            of reaching 1,000,000 impact outputs. Here’s what we have achieved
            so far:`}
            </Typography>
          </div>

          <div>
            <PNBold variant="h4">
              {signupMission ? numOfUsers : impactByEvent || "0"}
              /1,000,000
            </PNBold>
            <PNSemiBold variant="h6">
              {signupMission
                ? "Signups"
                : "Total impact outputs from this mission"}
            </PNSemiBold>
          </div>
          <div>
            <PNBold variant="h4">{totalMinted}/1,000,000</PNBold>
            <PNSemiBold variant="h6">Total funded impact outputs</PNSemiBold>
          </div>
        </CommunityImpact> */}
        <GlobalContribution>
          <div>
            <PNBold variant="h5">{header}</PNBold>
            <Typography variant="h6">{description}</Typography>
          </div>
          {signupMission ? (
            <>
              <a
                href="https://www.undp.org/content/undp/en/home/sustainable-development-goals.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CTAButton>Learn More</CTAButton>
              </a>
              <GraphImg
                image={backgroundImage}
                className="sdg-image"
              ></GraphImg>
            </>
          ) : (
            sdgs.map((sdg, sdgIndex) => {
              if (!subgoals[sdg.number]) {
                return null;
              }
              return (
                <div key={sdgIndex}>
                  <IconTextContainer>
                    <SDGIcon image={sdg.icon} />
                    <Grid item style={{ paddingLeft: "10px" }}>
                      <a
                        href={`https://www.undp.org/content/undp/en/home/sustainable-development-goals/goal-${sdg.number.toString()}-${sdg.name
                          .replace(/,/g, "")
                          .replace(/\s+/g, "-")
                          .replace(/&/g, "and")
                          .toLowerCase()}.html`}
                      >
                        <PNBold variant="h5">{sdg.name}</PNBold>
                      </a>
                      {Object.keys(subgoals[sdg.number]).map((sub, index) => (
                        <Typography
                          key={index}
                          align="left"
                          variant="h6"
                        >{`${sub} - ${subgoals[sdg.number][sub]}`}</Typography>
                      ))}
                    </Grid>
                  </IconTextContainer>
                </div>
              );
            })
          )}
        </GlobalContribution>
        <DiscoverPanel>
          <PNBold variant="h5" align="center" style={{ padding: "1rem 0rem" }}>
            Discover more ways to make an impact
          </PNBold>
          <MissionDiscovery />
        </DiscoverPanel>
      </MissionFinalGrid>
    </>
  );
}

export default withApollo(MissionFinal);
