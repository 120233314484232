import React from "react";
import { withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { CORPORATE } from "../../utilities/queries";
import { CorporateContainer } from "./CorporateStyles";
import { Banner, GridGap, BannerGraphImg } from "../shared/StyledComponents";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import EmailForm from "../shared/EmailForm";

function Corporate({ client }) {
  const { loading, data, error } = useQuery(CORPORATE, { client });
  if (loading && !data) return <Loading />;
  if (error) {
    return <ErrorView error={error}></ErrorView>;
  }

  const {
    header,
    headerBanner,
    headerTextColor,
    bodyHeader,
    bodyContent
  } = data.corporates[0];
  return (
    <CorporateContainer>
      <Banner>
        <div className="banner-text">
          <Typography variant="h1" style={{ color: headerTextColor.hex }}>
            {header}
          </Typography>
        </div>
        <BannerGraphImg image={headerBanner} />
      </Banner>

      <div className="corporate-body">
        <Typography variant="h3">{bodyHeader}</Typography>
        <GridGap className="content-right">
          <ReactMarkdown source={bodyContent.markdown} />
          <EmailForm
            buttonText="Contact Us"
            formWidth="50%"
            noFixedButton
            outlined
            hidden
            tags={["B2B"]}
          />
        </GridGap>
      </div>
    </CorporateContainer>
  );
}

export default withApollo(Corporate);
