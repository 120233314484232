import React from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox as CheckBase
} from "@material-ui/core";
import styled from "styled-components";

const Checkbox = styled(CheckBase)`
  .MuiSvgIcon-root {
    fill: ${props => props.fill};
  }
`;
function UserAgreements({ handleChange, checkedState, footer }) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.marketing}
            onChange={handleChange("marketing")}
            value="marketing"
            fill={footer ? "#ffffff" : "e0dcd5"}
          />
        }
        label={
          <Typography variant="subtitle2" style={{ lineHeight: "16px" }}>
            I consent to receive marketing communications from Proof of Impact
          </Typography>
        }
      />
    </>
  );
}

export default UserAgreements;
