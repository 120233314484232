import React from "react";
import { Spinner } from "reactstrap";

function Loading() {
  return (
    <div
      className="text-center"
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        display: "flex",
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Spinner color="primary" />
    </div>
  );
}

export default Loading;
