import React from "react";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import GraphImg from "graphcms-image";
import { Link } from "react-router-dom";
import { MARKETPLACE } from "../../utilities/queries";
import MarketplaceList from "./MarketplaceList";
import SidePanel from "./SidePanel";
// import ImpactShare from "./ImpactShare";
import GettingStarted from "../shared/GettingStarted";
import { PNBold, PNRegular } from "../shared/StyledComponents";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import {
  MarketplaceGrid,
  HeroContainer,
  SDGPanelGrid,
  MainContentContainer
} from "./MarketplaceStyles";
import { CTAButton } from "../shared/StyledComponents";
import FilterSubheader from "./FilterSubheader";

function Marketplace({ client }) {
  const isDesktop = useMediaQuery("(min-width:770px)");
  const initialFilterState = {
    Environmental: false,
    Social: false,
    Animal: false,
    Health: false
  };
  const [open, setOpen] = React.useState(initialFilterState);
  const [filter, setFilter] = React.useState();
  const [selectedIndex, setSelectedIndex] = React.useState();

  const handleFilterMenu = (category, index) => {
    const prevOpen = !open[category];
    if (category === filter) {
      setFilter(null);
      setSelectedIndex(null);
    } else {
      setFilter(category);
      setSelectedIndex(index);
    }
    category
      ? setOpen({ ...initialFilterState, [category]: prevOpen })
      : setOpen(initialFilterState);
  };
  const {
    loading: marketLoading,
    data: marketData,
    error: marketError
  } = useQuery(MARKETPLACE, { client });

  if (marketLoading && !marketData) return <Loading />;
  if (marketError) {
    return <ErrorView error={marketError} />;
  }

  const {
    mainHeader,
    mainDescription,
    headerTextColor,
    heroBanner,
    featuredEvent,
    featuredDescription,
    featuredHeader,
    mainEvents,
    comingSoonEvents,
    sdgBackground
    // shareHeader,
    // shareMessage,
    // impactBackground
  } = marketData.marketplaces[0];

  return (
    <>
      {!isDesktop && (
        <FilterSubheader
          handleFilterMenu={handleFilterMenu}
          selectedIndex={selectedIndex}
        />
      )}
      <MarketplaceGrid>
        <div className="hero">
          <HeroContainer container alignItems="center" direction="column">
            <Typography
              variant="h1"
              style={{
                padding: "20px 0px",
                color: headerTextColor.hex
              }}
            >
              {mainHeader}
            </Typography>
            <PNRegular variant="h5">{mainDescription}</PNRegular>
            <Link to="/signup">
              <CTAButton margin="1rem" width="15rem">
                <PNBold variant="h6">Your first mission</PNBold>
              </CTAButton>
            </Link>
          </HeroContainer>
          <GraphImg image={heroBanner} style={{ height: "540px" }}></GraphImg>
        </div>
        <MainContentContainer>
          <SidePanel
            open={open}
            handleFilterMenu={handleFilterMenu}
            selectedIndex={selectedIndex}
          />

          <MarketplaceList
            eventData={{
              featuredEvent,
              featuredDescription,
              featuredHeader,
              comingSoonEvents,
              mainEvents
            }}
            filter={filter}
          />
        </MainContentContainer>
        <GettingStarted />
        {/* <ImpactShare data={{ shareHeader, shareMessage, impactBackground }} /> */}
        <div className="sdg">
          <SDGPanelGrid>
            <Typography variant="h1" style={{ gridColumn: "1" }}>
              Sustainable Development Goals
            </Typography>
            <div style={{ gridColumn: "3/-1" }}>
              <PNBold variant="body1">
                The United Nations set up initiatives for a better world by
                2030. On our marketplace, you can measure and track your
                contributions to the global goals.
              </PNBold>
              <br />
              <a
                href="https://www.undp.org/content/undp/en/home/sustainable-development-goals.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CTAButton width="50%" height="fit-content">
                  <PNBold variant="body1">Learn More</PNBold>
                </CTAButton>
              </a>
            </div>
          </SDGPanelGrid>
          <GraphImg
            image={sdgBackground}
            style={{ height: "420px" }}
          ></GraphImg>
        </div>
      </MarketplaceGrid>
    </>
  );
}

export default withApollo(Marketplace);
