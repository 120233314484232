import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { PURCHASES, COMMITDRAWDOWNS } from "../../utilities/queries";
import { SETPURCHASEPAID, SETDRAWDOWNPAID } from "../../utilities/mutations";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import Button from "../shared/Button";

function SellerPaidTable({ client }) {
  const purchases = useQuery(PURCHASES, { client });
  const commitDrawdowns = useQuery(COMMITDRAWDOWNS, { client });
  const [setPurchasePaid, setPurchasePaidReturn] = useMutation(
    SETPURCHASEPAID,
    {
      client,
      refetchQueries: [{ query: PURCHASES }]
    }
  );
  const [setDrawdownPaid, setDrawdownPaidReturn] = useMutation(
    SETDRAWDOWNPAID,
    {
      client,
      refetchQueries: [{ query: COMMITDRAWDOWNS }]
    }
  );

  const payments = [];
  if (purchases && purchases.data && commitDrawdowns && commitDrawdowns.data) {
    for (const purchase of purchases.data.purchases) {
      payments.push({
        timestamp: new Date(purchase.timestamp).toString(),
        seller: purchase.event.seller.username,
        buyer: purchase.buyer.username,
        token: purchase.event.token.name,
        quantity: purchase.amount,
        price: purchase.price,
        dollarAmount: purchase.price * purchase.amount,
        sellerPaid: purchase.sellerPaid,
        type: "Purchase",
        id: purchase.id
      });
    }
    for (const commitDrawdown of commitDrawdowns.data.commitDrawdowns) {
      payments.push({
        timestamp: new Date(commitDrawdown.timestamp).toString(),
        seller: commitDrawdown.commitment.seller.username,
        buyer: commitDrawdown.commitment.buyer.username,
        token: commitDrawdown.commitment.token.name,
        quantity: commitDrawdown.amount,
        price: commitDrawdown.commitment.price,
        dollarAmount: commitDrawdown.commitment.price * commitDrawdown.amount,
        sellerPaid: commitDrawdown.sellerPaid,
        type: "Drawdown",
        id: commitDrawdown.id
      });
    }
  }
  return (
    <>
      {purchases.error ||
      commitDrawdowns.error ||
      setPurchasePaidReturn.error ||
      setDrawdownPaidReturn.error ? (
        <ErrorView
          error={
            purchases.error ||
            commitDrawdowns.error ||
            setPurchasePaidReturn.error ||
            setDrawdownPaidReturn.error
          }
        />
      ) : purchases.loading ||
        commitDrawdowns.loading ||
        setPurchasePaidReturn.loading ||
        setDrawdownPaidReturn.loading ? (
        <Loading />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Seller</TableCell>
              <TableCell>Buyer</TableCell>
              <TableCell>Token</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Amount $</TableCell>
              <TableCell>Paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, idx) => (
              <TableRow key={idx}>
                <TableCell>{payment.id}</TableCell>
                <TableCell>{payment.type}</TableCell>
                <TableCell>{payment.timestamp}</TableCell>
                <TableCell>{payment.seller}</TableCell>
                <TableCell>{payment.buyer}</TableCell>
                <TableCell>{payment.token}</TableCell>
                <TableCell>{payment.quantity}</TableCell>
                <TableCell>{payment.price}</TableCell>
                <TableCell>{payment.dollarAmount}</TableCell>
                <TableCell>
                  {payment.sellerPaid ? (
                    "TRUE"
                  ) : (
                    <Button
                      onClick={() => {
                        if (payment.type === "Purchase") {
                          setPurchasePaid({ variables: { id: payment.id } });
                        } else if (payment.type === "Drawdown") {
                          setDrawdownPaid({ variables: { id: payment.id } });
                        }
                      }}
                    >
                      MARK AS PAID
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default withApollo(SellerPaidTable);
