import React, { useState } from "react";
import { Carousel, CarouselItem } from "reactstrap";
import { withApollo } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";

import { SUBMITEVENT, CREATEEVENT } from "../../utilities/mutations";
import Slide from "../../components/InputApp/Slide";
import {
  schemaToMetadata,
  createEventHandler
} from "../../utilities/inputAppUtils";

function InputApp({ client, appDetails, ...props }) {
  const { components } =
    typeof appDetails.schema === "string"
      ? JSON.parse(appDetails.schema)
      : appDetails.schema;
  const metadataComponents = schemaToMetadata(components);
  const initialState = metadataComponents.reduce((state, data) => {
    state[data.stateVar] = data.defaultState;
    return state;
  }, {});
  const [page, setPage] = useState(0);
  const [state, setState] = useState(initialState);
  const [saved, setSaved] = useState(false);
  const [savedEventId, setSavedEventId] = useState(false);
  const [done, setDone] = useState(false);
  const [
    submitEvent,
    { loading: submitLoading, error: submitError }
  ] = useMutation(SUBMITEVENT, {
    client,
    onCompleted: () => handleSubmitComplete()
  });
  const [
    createEvent,
    { loading: createLoading, error: createError }
  ] = useMutation(CREATEEVENT, {
    client,
    onCompleted: async data => await handleCreateComplete(data)
  });

  const handleSubmitComplete = () => {
    setPage(page + 1);
    setDone(true);
  };
  const handleCreateComplete = async data => {
    await setSavedEventId(data.createEvent.id);
    await setSaved(true);
  };

  const navigateAndClear = () => {
    setSaved(false);
    setPage(page + 1);
  };

  const saveForLater = async () => {
    const { data } = await createEvent({
      variables: {
        ...(savedEventId && { id: savedEventId }),
        ...createEventHandler(state, metadataComponents, props.token.address)
      }
    });
    setSavedEventId(data.createEvent.id);
    return data;
  };

  const slides = components.map((component, i) => {
    return (
      <CarouselItem key={i}>
        <Slide
          details={component}
          i={i}
          user={props.loggedIn}
          setValues={setState}
          values={state}
          total={components.length}
          saved={saved}
          page={page}
          done={done}
          navHandlers={{ navigateAndClear, saveForLater, setPage, submitEvent }}
          errors={
            submitError || createError ? { submitError, createError } : null
          }
          loading={submitLoading || createLoading ? true : false}
          {...props}
        ></Slide>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={page}
      next={() => undefined}
      previous={() => undefined}
      className="bg-primary mw-100 mh-100"
    >
      {slides}
    </Carousel>
  );
}

export default withApollo(InputApp);
