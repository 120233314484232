import React from "react";
import { withApollo } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";

import ErrorView from "../ErrorView";
import { UPLOADANDPINFILE } from "../../utilities/mutations";
import resizeImageToFile from "../../utilities/resizeImageToFile";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(2)
  },
  editButton: {
    background: theme.palette.primary.main
  }
}));

function ImageInput({ client, setValues, values, details, edit }) {
  const { button, editButton } = useStyles();
  const { stateVar, title, required } = details;
  const [uploadImage, { loading, error }] = useMutation(UPLOADANDPINFILE, {
    client
  });
  return (
    <>
      {edit && (
        <>
          <Typography variant="h6">{title}</Typography>
        </>
      )}
      {loading && <CircularProgress />}
      {error && <ErrorView error={error} />}
      <Grid container justify={edit ? "flex-start" : "center"}>
        {values[stateVar].map((imgHash, i) => (
          <img
            key={i}
            src={`https://ipfs.poip.io/ipfs/${imgHash}`}
            alt=""
            width="100"
            height="100"
            className="d-block"
            style={{ marginRight: "10px" }}
          />
        ))}
      </Grid>

      <Grid item>
        <label
          className={edit ? `${button} ${editButton}` : button}
          style={{ cursor: "pointer" }}
        >
          SELECT OR TAKE PHOTOS
          <input
            type="file"
            accept="image/*"
            capture="environment"
            multiple
            required={required}
            tabIndex="-1"
            style={{
              opacity: 0,
              overflow: "hidden",
              width: 1,
              height: 1
            }}
            onChange={({ target: { files } }) => {
              const resizePromises = Object.values(files).map(file => {
                return resizeImageToFile(file).then(async fileObj => {
                  const {
                    data: { uploadAndPinFile }
                  } = await uploadImage({
                    variables: { file: fileObj }
                  });
                  return uploadAndPinFile;
                });
              });
              return Promise.all(resizePromises).then(hashes => {
                setValues({ ...values, [stateVar]: hashes });
              });
            }}
          />
        </label>
      </Grid>
    </>
  );
}

export default withApollo(ImageInput);
