import React from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(2)
  },
  editButton: {
    background: theme.palette.primary.main
  }
}));
function GeolocationInput({ values, setValues, details, handlers, edit }) {
  const { button, editButton } = useStyles();
  const { stateVar, title, required } = details;
  const getGeolocation = () => {
    if (process.env.NODE_ENV === "production") {
      window.navigator.geolocation.getCurrentPosition(position => {
        const coordinates = {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString()
        };
        setValues({ ...values, [stateVar]: coordinates });
      });
    } else {
      const demoCoordinates = {
        latitude: "-33.9249",
        longitude: "18.4241"
      };
      setValues({ ...values, [stateVar]: demoCoordinates });
    }
  };
  return (
    <>
      {edit && (
        <Grid item xs={12} md={7} lg={7}>
          <TextField
            disabled
            fullWidth
            required={required}
            value={`${values[stateVar].latitude}, ${values[stateVar].longitude}`}
            InputLabelProps={{
              shrink: true
            }}
            label={title}
            variant="outlined"
            margin="normal"
          />
        </Grid>
      )}
      <Button
        className={edit ? `${button} ${editButton}` : button}
        onClick={async () => {
          if (!edit) {
            await getGeolocation();
            handlers.saveForLater();
            handlers.navigateAndClear();
          }
          return await getGeolocation();
        }}
      >
        Request Location
      </Button>
    </>
  );
}

export default GeolocationInput;
