import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import TrendingFlatRoundedIcon from "@material-ui/icons/TrendingFlatRounded";

import detailsBg from "../../assets/images/buyerDash/david-troeger-9-xzy-ez-pahmi-unsplash-copy-3.jpg";
import { useAuth0 } from "../Auth0Provider";

const FixedHeightPaper = styled(Paper)`
  height: 400px;
  background-color: #e0dcd5;
`;

function MissionDetails({ lastTransaction }) {
  const { user } = useAuth0();
  return (
    <FixedHeightPaper>
      <div
        style={{
          backgroundImage: `url(${detailsBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "200px",
          borderBottom: "4px solid #4371cc"
        }}
      >
        <div
          style={{
            height: "70px",
            width: "100%",
            backgroundColor: "rgba(22, 22, 26, 0.6)",
            textShadow: "0px 0px 8px #000000",
            borderRadius: "4px 4px 0px 0px"
          }}
        >
          <div style={{ height: "20px" }} />
          <Typography
            variant="body1"
            style={{ marginLeft: "30px", color: "white" }}
          >
            Last{" "}
            {user["https://poip.io/roles"][0] === "BUYER" ? "Mission" : "Sale"}{" "}
            Details
          </Typography>
        </div>
      </div>
      {lastTransaction && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ margin: "15px" }}>
              {lastTransaction.event.token.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                textAlign: "center"
              }}
            >
              <div style={{ marginTop: "40px" }}>
                <Typography variant="body2">
                  {lastTransaction.amount} impact outcomes
                </Typography>
              </div>
              <TrendingFlatRoundedIcon
                style={{ transform: "rotate(90deg)", margin: "20px" }}
              />
              <div>
                <Typography variant="body2">
                  $
                  {lastTransaction.amount *
                    (lastTransaction.price ||
                      lastTransaction.commitment.price)}{" "}
                  mission total
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </FixedHeightPaper>
  );
}

export default MissionDetails;
