import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";

import OrderTable from "./OrderTable";
import UserTable from "./UserTable";
import NewUser from "./NewUser";
import SellerPaidTable from "./SellerPaidTable";
import SubmittedTable from "./SubmittedTable";
import NewPurchase from "./NewPurchase";
import { useAuth0 } from "../Auth0Provider";

const tabs = {
  "Impact Submissions": SubmittedTable,
  "Orders": OrderTable,
  "User List": UserTable,
  "New User": NewUser,
  "Creator Payouts": SellerPaidTable,
  "Manual Purchase": NewPurchase
};

const tabHeaders = Object.keys(tabs).map((header, idx) => (
  <Tab key={idx} label={header} />
));

function Admin() {
  const { logout } = useAuth0();
  const [tabIndex, setTabIndex] = useState(0);
  const Content = Object.values(tabs)[tabIndex];
  return (
    <Container>
      <br />
      <Button onClick={() => logout({ returnTo: window.location.origin })}>
        Log Out
      </Button>
      <br />
      <Tabs value={tabIndex} onChange={(e, idx) => setTabIndex(idx)} centered>
        {tabHeaders}
      </Tabs>
      <br />
      <Content />
      <br />
    </Container>
  );
}

export default Admin;
