import React from "react";

import GqlSubmit from "../gqlwrappers/GqlSubmit";
import SubmitBody from "../components/SubmitBody";
import ErrorView from "../components/ErrorView";

function Submit(props) {
  if (process.env.REACT_APP_BACKEND_ENV !== "production")
    return (
      <GqlSubmit
        {...props}
        render={submitProps => <SubmitBody {...props} {...submitProps} />}
      />
    );
  return (
    <ErrorView
      error={{ message: "This impact event does not have a submission app" }}
    />
  );
}

export default Submit;
