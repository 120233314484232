import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GraphImg from "graphcms-image";
import { PNRegular, CTAButton, PNBold } from "../shared/StyledComponents";

const FeaturedContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: #e0dcd5;
  @media only screen and (min-width: 770px) {
    grid-template-columns: 60% 1fr;
  }
`;

const FeaturedText = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;

  .padding {
    padding: 1rem 2rem;
  }

  & button {
    margin: 1rem auto;
    @media only screen and (min-width: 770px) {
      margin: 0rem 2rem;
    }
  }
`;

function FeaturedEvent(props) {
  const { displayImage, route } = props.event;
  const { featuredHeader, featuredDescription } = props;
  return (
    <Link
      to={`/events/${route}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <FeaturedContainer>
        <GraphImg image={displayImage} />
        <FeaturedText>
          <PNBold variant="h3" className="padding">
            {featuredHeader}
          </PNBold>
          <PNRegular variant="h5" className="padding">
            {featuredDescription}
          </PNRegular>
          <CTAButton>
            <PNBold variant="h6">Learn More</PNBold>
          </CTAButton>
        </FeaturedText>
      </FeaturedContainer>
    </Link>
  );
}

export default FeaturedEvent;
