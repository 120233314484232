import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { Cookies } from "react-cookie-consent";
import {
  TextField,
  FormGroup as FormBase,
  useMediaQuery,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { CTAButton, PNSemiBold } from "./StyledComponents";
import { SUBMITCONTACTFORM } from "../../utilities/mutations";
import Loading from "../Loading";
import UserAgreements from "./UserAgreements";
import ErrorView from "../ErrorView";
import styled from "styled-components";

const FormGroup = styled(FormBase)`
  width: "100%";
  @media only screen and (min-width: 770px) {
    width: ${props => props.width || "100%"};
  }
`;

function EmailForm({
  client,
  buttonText,
  outlined,
  noLabel,
  footer,
  buttonWidth = "100%",
  formWidth,
  noFixedButton,
  tags = []
}) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const [message, setMessage] = useState();
  const [newContact, { error, loading, data }] = useMutation(
    SUBMITCONTACTFORM,
    { client }
  );
  const [checkedState, setCheckedState] = useState({
    email: "",
    marketing: false
  });

  if (loading) return <Loading />;

  const handleChange = name => event => {
    setCheckedState({ ...checkedState, [name]: event.target.checked });
  };

  const handleSubmit = () => {
    const acceptedCookie = Cookies.get("CookieConsent");
    if (!acceptedCookie) {
      setMessage("Please accept our use of cookies before proceeding.");
    } else {
      newContact({
        variables: {
          formDetails: {
            email: checkedState.email,
            gdprAcceptance: Boolean(acceptedCookie),
            marketingOptIn: checkedState.marketing,
            source: window.location.href,
            tags
          }
        }
      });
      setMessage(null);
    }
  };

  return (
    <>
      {error ? (
        <>
          <ErrorView error={error} hidden />
          <Typography variant="h5">
            There was an error in submitting your information. Please try again.
          </Typography>
        </>
      ) : data ? (
        <Typography variant="h4" style={{ margin: "1.5rem 0rem" }}>
          Success! We'll be in touch.
        </Typography>
      ) : (
        <FormGroup width={formWidth}>
          {message && <PNSemiBold variant="h6">{message}</PNSemiBold>}
          <TextField
            type="text"
            name="email"
            label={noLabel ? "" : "Enter email"}
            margin="normal"
            variant={outlined ? "outlined" : "standard"}
            placeholder="email@email.com"
            InputLabelProps={{
              shrink: true
            }}
            style={{
              width: "100%",
              background: outlined ? "#f2f2f2" : "transparent"
            }}
            onChange={({ target: { value } }) =>
              setCheckedState({ ...checkedState, email: value })
            }
          />
          <UserAgreements
            handleChange={handleChange}
            checkedState={checkedState}
            footer={footer}
          />
          <CTAButton
            style={
              isDesktop
                ? {}
                : !noFixedButton
                ? {
                    bottom: 0,
                    left: 0,
                    position: "fixed",
                    margin: 0,
                    borderRadius: "0"
                  }
                : {}
            }
            disabled={!checkedState.email}
            margin={"1rem 0rem"}
            onClick={handleSubmit}
            width={buttonWidth}
          >
            {buttonText}
          </CTAButton>
          <Typography variant="subtitle2">
            Questions? View our <Link to="/privacy">privacy policy</Link>
          </Typography>
        </FormGroup>
      )}
    </>
  );
}

export default withApollo(EmailForm);
