import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body, html, #root {
    min-height: 100vh;
    min-width: 100vw;
  }

  :root {
      font-size: 14px;
  }

  a, a:hover, a:visited, a:link, a:active {
    color: #3165cc;
    text-decoration: none !important;
  }
 
`;

export default Global;
