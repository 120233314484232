import React from "react";
import { Typography } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import GraphImg from "graphcms-image";
import * as Sentry from "@sentry/browser";
import { PNRegular, CTAButton } from "./shared/StyledComponents";

import styled from "styled-components";

const ErrorContainer = styled.div`
  display: grid;
  margin: 2rem;
  margin-bottom: 4rem;
  text-align: center;
  grid-gap: 1rem;
  width: 80%;
  @media only screen and (min-width: 770px) {
    width: 40%;
  }
  .justify-center {
    justify-self: center;
  }
`;

function ErrorView({ error, eventId, hidden, notFound }) {
  const errorId = eventId || Sentry.captureException(error);
  if (notFound) return <Redirect to="/" />;
  if (hidden) return null;
  return (
    <ErrorContainer>
      <GraphImg
        image={{ handle: "CxWESUM2QGu61e3sjbBq", width: 1280, height: 720 }}
      ></GraphImg>
      <Typography variant="h1">Error</Typography>
      <PNRegular variant="h5">
        {error ? error.message : "You found a broken link."}
      </PNRegular>

      <CTAButton className="justify-center" width="17rem">
        <Link to="/">Home</Link>
      </CTAButton>
      <CTAButton
        className="justify-center"
        width="17rem"
        onClick={() => Sentry.showReportDialog({ eventId: errorId })}
      >
        Report Feedback
      </CTAButton>
    </ErrorContainer>
  );
}

export default ErrorView;
