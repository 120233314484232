import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { ACTIVEEVENTS } from "../../utilities/queries";
import EventCard from "../shared/EventCard";
import { EventGrid } from "./MissionStyles";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

function MissionDiscovery({ client }) {
  const { loading, data, error } = useQuery(ACTIVEEVENTS, { client });
  if (loading && !data) return <Loading />;
  if (error) {
    return <ErrorView error={error} />;
  }
  const { mainEvents } = data.marketplaces[0];
  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
  return (
    <>
      <EventGrid>
        {getRandom(mainEvents, 3).map((event, i) => {
          return (
            <li key={i}>
              <EventCard event={event} height={"134px"} width={"238px"} />
            </li>
          );
        })}
      </EventGrid>
    </>
  );
}

export default withApollo(MissionDiscovery);
