import React from "react";
import { InputAdornment, TextField, Grid } from "@material-ui/core";

function MultiFieldInput({ details, setValues, values, edit }) {
  const { stateVar, placeholder, adornment, required } = details;
  return (
    <>
      {(details.fieldNames
        ? details.fieldNames
        : Object.keys(details.value)
      ).map((field, i) => {
        return (
          <Grid xs={12} md={edit ? 7 : 3} lg={edit ? 7 : 3} item key={i}>
            <TextField
              label={field}
              fullWidth
              required={required}
              margin="normal"
              variant="outlined"
              value={values[stateVar][field]}
              placeholder={placeholder ? placeholder : null}
              InputProps={{
                endAdornment: adornment ? (
                  <InputAdornment position="end">{adornment}</InputAdornment>
                ) : null
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={({ target }) => {
                const { value: val } = target;
                setValues(prev => {
                  return {
                    ...prev,
                    [stateVar]: { ...values[stateVar], [field]: val }
                  };
                });
              }}
            />
          </Grid>
        );
      })}
    </>
  );
}

export default MultiFieldInput;
