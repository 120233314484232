import React from "react";
import moment from "moment";
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(2)
  },
  editButton: {
    background: theme.palette.primary.main
  }
}));

function DateTimeInput({ setValues, values, handlers, edit, details }) {
  const { button, editButton } = useStyles();
  const { stateVar, required } = details;
  return (
    <>
      <br />
      <Grid item>
        <TextField
          type="date"
          label={"Date"}
          required={required}
          variant="outlined"
          value={values[stateVar].date}
          onChange={({ target: { value } }) =>
            setValues({
              ...values,
              [stateVar]: { ...values[stateVar], date: value }
            })
          }
          InputLabelProps={{
            shrink: true
          }}
          style={{ marginRight: "10px" }}
        />
        <br />
        <br />
        <TextField
          type="time"
          label={"Time"}
          variant="outlined"
          required={required}
          value={values[stateVar].time}
          onChange={({ target: { value } }) =>
            setValues({
              ...values,
              [stateVar]: { ...values[stateVar], time: value }
            })
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <br />
      {!edit ? (
        <Grid container justify="center" item>
          <Button
            className={edit ? `${button} ${editButton}` : button}
            onClick={() => {
              if (
                !moment(
                  `${values[stateVar].date} ${values[stateVar].time}`
                ).isValid()
              ) {
                alert(
                  `Invalid Date & Time: ${values[stateVar].date} ${values[stateVar].time}`
                );
              } else {
                handlers.saveForLater();
                handlers.navigateAndClear();
              }
            }}
          >
            Save Date & Time & Continue
          </Button>
        </Grid>
      ) : null}
    </>
  );
}

export default DateTimeInput;
