import React, { useState, Fragment } from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import SocialShare from "../shared/SocialShare";
import EmailForm from "../shared/EmailForm";
import { EVENTS, PRICES } from "../../utilities/queries";
import { useAuth0 } from "../Auth0Provider";
import {
  FundingOptionButton,
  DonateButton,
  FundingInput,
  FundingSidebarContainer
} from "./MissionStyles";
import {
  Divider,
  GridGap,
  PNBold,
  PNRegular
} from "../shared/StyledComponents";
import { shopifyClient } from "../../utilities/shopify";

function MissionFundingForm({ client, data, sdgs, activeEvent, ...props }) {
  const {
    header,
    description,
    fundingOptions,
    unit,
    sellerHomepage,
    sampleReport
  } = data;
  const isDesktop = useMediaQuery("(min-width:960px)");
  const { color, category } = sdgs[0];
  const [eventCount, setEventCount] = useState();
  const [activeOption, setActiveOption] = useState();
  const { user, loginWithRedirect } = useAuth0();

  const {
    loading: eventsLoading,
    error: eventsError,
    data: eventsData
  } = useQuery(EVENTS, {
    variables: { route: props.match.params.tokenRoute },
    client
  });

  const {
    loading: pricesLoading,
    error: pricesError,
    data: pricesData
  } = useQuery(PRICES, {
    variables: { route: props.match.params.tokenRoute },
    client
  });

  if ((eventsLoading && !eventsData) || (pricesLoading && !pricesData))
    return <Loading />;
  if (eventsError || pricesError)
    return <ErrorView error={eventsError || pricesError} />;

  let eventPrice;
  for (const price of pricesData.prices) {
    if (price.seller) continue;
    if (price.price) eventPrice = price.price;
  }

  let totalAvailableEvents = 0;
  for (const event of eventsData.events) {
    if (
      !(
        event.token.route === props.match.params.tokenRoute &&
        event.productVariant.availableForSale &&
        event.productVariant.inventoryQuantity > 0
      )
    )
      continue;
    totalAvailableEvents += event.productVariant.inventoryQuantity;
  }
  const validRole = user && user["https://poip.io/roles"][0] === "BUYER";
  const validFundingOption =
    eventCount > 0 && eventCount <= totalAvailableEvents;

  const handleDonationOption = async number => {
    setActiveOption(number);
    if (!user)
      loginWithRedirect({ appState: { targetPath: window.location.pathname } });
    if (user["https://poip.io/roles"][0] !== "BUYER") {
      alert("Must be logged in as a buyer account");
      return;
    }
    let amountRemaining = number || eventCount;
    let lineItems = [];
    const events = eventsData.events.filter(
      e =>
        e.token.route === props.match.params.tokenRoute &&
        e.productVariant.availableForSale &&
        e.productVariant.inventoryQuantity > 0
    );
    events.sort((a, b) => a.timestamp > b.timestamp);
    for (const event of events) {
      const quantity = Math.min(
        amountRemaining,
        event.productVariant.inventoryQuantity
      );
      lineItems.push({
        variantId: event.productVariant.storefrontId,
        quantity
      });
      amountRemaining -= quantity;
      if (amountRemaining <= 0) break;
    }
    if (amountRemaining > 0) {
      alert("Not enough events in stock");
      return;
    }
    if (lineItems.length) {
      let checkout = await shopifyClient.checkout.create({
        email: user.email,
        customAttributes: [
          { key: "username", value: user["https://poip.io/username"] }
        ],
        note: `For user: ${user["https://poip.io/username"]}`
      });
      checkout = await shopifyClient.checkout.addLineItems(
        checkout.id,
        lineItems
      );
      window.location.href = checkout.webUrl;
    }
  };

  const eventTag = window.location.pathname.split('/events/')[1]

  return (
    <>
      <FundingSidebarContainer>
        <Divider
          color={color.hex}
          height={"4px"}
          style={{ marginBottom: ".5rem" }}
        />
        <Grid container justify="space-between" style={{ padding: "1rem 0px" }}>
          <PNBold
            variant="h6"
            style={{ color: color.hex, alignSelf: "center" }}
          >
            {category}
          </PNBold>
          <SocialShare
            color={color.hex}
            header={header}
            description={description}
          ></SocialShare>
        </Grid>

        <GridGap>
          <Typography variant="h2" style={{ lineHeight: "4.25rem" }}>
            {header}
          </Typography>
          <Typography variant="body1" style={{ lineHeight: "1.33em" }}>
            {description}
          </Typography>
          <a href={sellerHomepage} style={{ color: "#4371cc" }}>
            <PNBold variant="h6">More about this impact creator</PNBold>
          </a>
        </GridGap>
      </FundingSidebarContainer>
      <div>
        {process.env.REACT_APP_ALLOW_DONATIONS === "true" &&
        activeEvent &&
        fundingOptions &&
        eventPrice ? (
          <Grid container item justify="center">
            {Object.keys(fundingOptions).map((quantity, i) => (
              <FundingOptionButton
                isActive={activeOption === quantity}
                onClick={() => handleDonationOption(parseInt(quantity))}
                key={i}
              >
                <PNBold variant="h6">{`$${(
                  parseFloat(quantity) * parseFloat(eventPrice)
                ).toFixed(2)}`}</PNBold>
                <PNBold variant="h6">{`${fundingOptions[quantity]}`}</PNBold>
              </FundingOptionButton>
            ))}

            <FundingInput
              placeholder={`Enter number of ${unit}s`}
              value={eventCount}
              onChange={e => setEventCount(e.target.value)}
            ></FundingInput>
            <DonateButton
              style={isDesktop ? {} : { bottom: 0, position: "fixed" }}
              onClick={() => handleDonationOption(eventCount)}
              disabled={!(validRole && validFundingOption)}
            >
              <PNBold
                variant="h6"
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em"
                }}
              >
                Donate
              </PNBold>
            </DonateButton>
          </Grid>
        ) : (
          <Fragment>
            <Typography variant="h6" style={{ lineHeight: "20px" }}>
              <b>You will be able to donate to this mission soon. </b>
              Sign up for our mailing list and we'll let you know when it's
              available.
            </Typography>
            <EmailForm buttonText="Sign up" tags={["B2C", `coming-soon-${eventTag}`]}/>
          </Fragment>
        )}
        <GridGap
          gap={".5rem"}
          style={isDesktop ? {} : { paddingBottom: "5.5rem" }}
        >
          {sampleReport ? (
            <a
              href={sampleReport.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PNBold variant="h6">Proof of Impact Report</PNBold>
            </a>
          ) : (
            <PNBold variant="h6">Sample Report Coming Soon</PNBold>
          )}
          <Divider style={{ height: "2px" }} />
          <PNRegular variant="h6" style={{ lineHeight: "1.2em" }}>
            Every purchase is backed by unique, rich data that proves the impact
            you supported.
          </PNRegular>
        </GridGap>
      </div>
    </>
  );
}

export default withApollo(MissionFundingForm);
