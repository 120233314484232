import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

import POISocial from "./POISocial";
import { InconsolataBold, PNSemiBold } from "../shared/StyledComponents";

const GettingStartedContainer = styled.div`
  grid-area: gettingstarted;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  padding: 3rem 0rem;
  background-color: #4371cc;
  color: white;
  justify-items: center;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: 770px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    text-align: left;
    padding: 3rem 0rem 3rem 2rem;
  }
  @media only screen and (min-width: 1920px) {
    padding: 3rem 15rem;
  }
`;

const WhiteCard = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  grid-template-columns: 1fr;
  background: white;
  color: #4371cc;
  padding: 1.5em 2em;
  text-align: center;
  border-radius: 4px;
  border: solid 2px #4371cc;
  grid-gap: 1.5em;
  width: 304px;
  height: 190px;

  button {
    border-radius: 4px;
    border: solid 1px #4371cc;
    color: #4371cc;
    background: white;
    padding: 0.5em 1em;

    a {
      text-decoration: none;
    }
  }
  .top-row {
    display: flex;
    justify-content: space-between;
  }

  .align-center {
    align-self: center;
  }
  .social {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: solid 1px #4371cc;
    border-radius: 4px;
  }
`;

function GettingStarted() {
  const topRow = step => (
    <div className="top-row">
      <Typography>{`0${step}`}</Typography>
      <span>
        <FaArrowRight></FaArrowRight>
      </span>
    </div>
  );
  return (
    <GettingStartedContainer>
      <Grid>
        <Typography variant="h3" style={{ paddingBottom: ".5em" }}>
          Getting Started
        </Typography>
        <PNSemiBold variant="body1">
          Whether you want to stay connected or are ready to back an Impact
          Mission, take action today!
        </PNSemiBold>
      </Grid>

      <WhiteCard>
        {topRow(1)}
        <Typography variant="h5">Discover impact</Typography>
        <button>
          <Link to="/">
            <InconsolataBold variant="subtitle2">
              Explore Marketplace
            </InconsolataBold>
          </Link>
        </button>
      </WhiteCard>

      <WhiteCard>
        {topRow(2)}
        <Typography variant="h5">Join the community</Typography>
        <div className="social">
          <POISocial
            color={"#4371cc"}
            height={"1.5em"}
            width={"1.5em"}
            border={"#4371cc"}
          ></POISocial>
        </div>
      </WhiteCard>

      <WhiteCard>
        {topRow(3)}
        <Typography variant="h5">Change the world</Typography>
        <button>
          <Link to="/signup">
            <InconsolataBold variant="subtitle2">Sign up</InconsolataBold>
          </Link>
        </button>
      </WhiteCard>
    </GettingStartedContainer>
  );
}

export default GettingStarted;
