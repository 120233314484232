import styled from "styled-components";

const CorporateContainer = styled.div`
  display: grid;
  grid-template-areas:
    "hero"
    "main"
    "footer";

  .corporate-body {
    grid-area: main;
    display: grid;
    grid-template-columns: 1fr;
    padding: 2rem;
    text-align: center;
    .content-right {
      width: 80%;
      justify-self: center;
      padding-top: 1rem;
      font-family: "proxima_novaregular";
      font-weight: normal;
      font-size: 1.429rem;
      button {
        justify-self: center;
        @media only screen and (min-width: 770px) {
          justify-self: left;
        }
      }
    }

    @media only screen and (min-width: 770px) {
      grid-template-columns: 1fr 2fr;
      padding: 5rem 14.5rem;
      text-align: left;
    }
  }
`;

export { CorporateContainer };
