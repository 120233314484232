import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ReportAccordionSection from "./ReportAccordionSection";

import oceanBg from "../../assets/images/buyerDash/marek-okon-t-ww-cq-i-mi-umg-unsplash-1@3x.jpg";

function Report({ purchases, order }) {
  let totalAmount = 0;
  let totalCost = 0;
  let latestDate;
  if (purchases) {
    purchases.forEach(purchase => {
      totalAmount += purchase.amount;
      totalCost += 1.1 * purchase.amount * purchase.price;
      latestDate = latestDate || purchase.timestamp;
    });
  } else {
    totalCost = parseFloat(order.totalCapturableSet.presentmentMoney.amount);
    latestDate = order.processedAt;
    order.lineItems.edges.forEach(({ node }) => {
      totalAmount += node.quantity;
    });
  }
  return (
    <Grid container style={{ marginBottom: "25px", flexGrow: 1 }}>
      <div style={{ height: "50px" }} />
      <Grid container style={{ marginTop: "0px" }}>
        <Grid item xs={12} sm={5}>
          <Container>
            <Box bgcolor="black" width="85%" height="4px" marginTop="45px" />
            <Typography
              variant="subtitle1"
              style={{ marginTop: "40px", marginBottom: "20px" }}
            >
              Proof of Purchase
            </Typography>
            <Typography variant="h2" style={{ marginBottom: "45px" }}>
              Impact Report
            </Typography>
            {order && (
              <Typography style={{ marginBottom: "45px" }}>
                NOTE: This order is pending admin review.
              </Typography>
            )}
            <Table>
              <TableBody>
                <TableRow style={{ borderBottom: "solid 2px #16161a" }}>
                  <TableCell>
                    <Typography variant="subtitle1">Receipt</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow style={{ borderTop: "dotted 2px #16161a" }}>
                  <TableCell component="th" scope="row">
                    Total Impact Amount
                  </TableCell>
                  <TableCell align="right">{totalAmount}</TableCell>
                </TableRow>
                <TableRow style={{ borderTop: "dotted 2px #16161a" }}>
                  <TableCell component="th" scope="row">
                    Fee
                  </TableCell>
                  <TableCell align="right">10%</TableCell>
                </TableRow>
                <TableRow style={{ borderTop: "dotted 2px #16161a" }}>
                  <TableCell component="th" scope="row">
                    Total Cost
                  </TableCell>
                  <TableCell align="right">${totalCost.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow style={{ borderTop: "dotted 2px #16161a" }}>
                  <TableCell component="th" scope="row">
                    Purchase Date
                  </TableCell>
                  <TableCell align="right">
                    {new Date(latestDate).toString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Container>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box flexGrow={1} bgcolor="#e0dcd5" height="110%">
            <Box
              style={{
                backgroundImage: `url(${oceanBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "350px",
                textShadow: "0px 0px 8px #000000",
                color: "white"
              }}
            >
              <Box
                justifyContent="center"
                alignContent="center"
                textAlign="center"
                bgcolor="rgba(22, 22, 26, 0.6)"
                height="70px"
                width="100%"
              >
                <Typography style={{ paddingTop: "20px" }}>
                  {purchases ? "Completed" : "Pending"} Mission
                </Typography>
              </Box>
              <Box textAlign="center" marginTop="50px">
                <Typography variant="h2">{totalAmount}</Typography>
                <Typography>total impact</Typography>
              </Box>
            </Box>
            <Container style={{ padding: "50px" }}>
              {purchases
                ? purchases.map((purchase, idx) => (
                    <ReportAccordionSection
                      startExpanded={idx === 0}
                      purchase={purchase}
                      key={idx}
                    />
                  ))
                : order.lineItems.edges.map(({ node }, idx) => (
                    <ReportAccordionSection
                      startExpanded={idx === 0}
                      lineItem={node}
                      key={idx}
                    />
                  ))}
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Report;
