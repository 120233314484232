import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "./ErrorView";
import Loading from "../components/Loading";
import InputApp from "./InputApp";
import { useAuth0 } from "./Auth0Provider";
import { USER, INPUTAPP } from "../utilities/queries";

function SubmitBody(props) {
  const token = props.token;
  let { user } = useAuth0();
  const { loading, data, error } = useQuery(USER, {
    variables: {
      username: user["https://poip.io/username"]
    },
    client: props.client
  });
  const {
    loading: inputAppLoading,
    data: inputAppData,
    error: inputAppError
  } = useQuery(INPUTAPP, {
    variables: {
      sellerId: user.sub,
      tokenId: token.uuid
    },
    client: props.client
  });
  if (loading || inputAppLoading) return <Loading />;
  if (error || inputAppError) {
    return <ErrorView error={error || inputAppError} />;
  }
  user = Object.assign(user, data.user);
  // TODO: Solve for case where a token & seller has multiple apps for one event
  const { inputApps } = inputAppData;
  if (process.env.REACT_APP_BACKEND_ENV === "production")
    return (
      <ErrorView
        error={{ message: "Input apps are currently under construction" }}
      />
    );

  if (!token) return <ErrorView error={{ message: "Token not found" }} />;
  if (!user) return <ErrorView error={{ message: "You are not logged in" }} />;
  if (user["https://poip.io/roles"][0] !== "SELLER")
    return <ErrorView error={{ message: "Logged in user is not a seller" }} />;
  if (inputApps.length > 0) {
    return <InputApp appDetails={inputApps[0]} {...props} loggedIn={user} />;
  }
  return (
    <ErrorView
      error={{ message: "This impact event does not have a submission app" }}
    />
  );
}

export default withApollo(SubmitBody);
