import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { NEWUSER } from "../../utilities/mutations";
import ErrorView from "../ErrorView";
import Button from "../shared/Button";
import { GridGap, CTAButton } from "../shared/StyledComponents";

function NewUser({ signupMission, client }) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const [newUser, { error, loading, data }] = useMutation(NEWUSER, {
    client
  });

  return (
    <>
      <FormGroup>
        <form
          onSubmit={e => {
            e.preventDefault();
            const {
              email,
              username,
              password,
              passwordConfirm,
              role
            } = e.target;
            if (password.value !== passwordConfirm.value) {
              alert("Passwords do not match");
              return;
            }
            newUser({
              variables: {
                email: email.value,
                username: username.value,
                password: password.value,
                ...(role && { role: role.value })
              }
            });
          }}
        >
          {signupMission ? (
            <>
              <GridGap gap={".5rem"}>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  margin="normal"
                  placeholder="email@email.com"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  type="text"
                  name="username"
                  label="Username"
                  margin="normal"
                  placeholder="proofofimpact"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  margin="normal"
                  placeholder="********"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  type="password"
                  name="passwordConfirm"
                  label="Confirm Password"
                  margin="normal"
                  placeholder="********"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </GridGap>
              <CTAButton style={isDesktop ? {} : { bottom: 0, left: 0, position: 'fixed', margin: 0, borderRadius: '0' }} type="submit" disabled={loading} margin={"2em 0em"}>
                Register
              </CTAButton>
            </>
          ) : (
              <>
                <TextField
                  type="email"
                  name="email"
                  variant="outlined"
                  label="Email"
                  margin="normal"
                />
                <TextField
                  type="text"
                  name="username"
                  variant="outlined"
                  label="Username"
                  margin="normal"
                />
                <TextField
                  type="password"
                  name="password"
                  variant="outlined"
                  label="Password"
                  margin="normal"
                />
                <TextField
                  type="password"
                  name="passwordConfirm"
                  variant="outlined"
                  label="Confirm Password"
                  margin="normal"
                />
                <FormControl>
                  <InputLabel>Role</InputLabel>
                  <NativeSelect defaultValue="BUYER" name="role">
                    <option value="BUYER">Buyer</option>
                    <option value="SELLER">Seller</option>
                  </NativeSelect>
                </FormControl>
                <Button type="submit" disabled={loading}>
                  Register
              </Button>
              </>
            )}
        </form>
      </FormGroup>
      {error && <ErrorView error={error} />}
      {data && "Success!"}
    </>
  );
}

export default withApollo(NewUser);
