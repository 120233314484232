import styled from "styled-components";
import Button from "@material-ui/core/Button";

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.palette.primary.main};
  color: white;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  margin: ${props => props.theme.spacing(1)};
`;

export default StyledButton;
