import styled from "styled-components";
import { Grid, ListItemText } from "@material-ui/core";

const MarketplaceGrid = styled.div`
  display: grid;
  grid-template-areas:
    "hero"
    "header"
    "main"
    "content"
    "gettingstarted"
    "impact"
    "sdg"
    "footer";

  @media only screen and (min-width: 770px) {
    grid-template-columns: 20% auto;
    grid-template-areas:
      "hero  hero"
      "header header"
      "main main"
      "gettingstarted gettingstarted"
      "impact impact"
      "sdg sdg"
      "footer footer";
  }

  @media only screen and (min-width: 1440px) {
    grid-template-areas:
      "hero hero hero"
      "header header header"
      "main main main"
      "gettingstarted gettingstarted gettingstarted"
      "impact impact impact"
      "sdg sdg sdg"
      "footer footer footer";
  }

  .hero {
    grid-area: hero;
    position: relative;
    display: grid;
  }

  .sdg {
    grid-area: sdg;
    position: relative;
    display: grid;
  }

  .header {
    grid-area: header;
    text-align: center;
    padding-top: 2rem;
  }
`;

const HeroContainer = styled(Grid)`
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-self: center;
  width: 80%;
  text-align: center;
  @media only screen and (min-width: 770px) {
    width: 50%;
  }
`;

const SDGPanelGrid = styled.div`
  position: absolute;
  z-index: 2;
  display: grid;
  grid-gap: 1rem;
  color: white;
  align-self: center;
  padding: 1.5em 3em;
  text-align: center;

  @media only screen and (min-width: 770px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 0rem 15rem;
    text-align: left;
  }
`;

const MainContentContainer = styled.div`
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "content"
    "sidepanel";

  @media only screen and (min-width: 740px) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "sidepanel content";
  }

  @media only screen and (min-width: 1920px) {
    padding-right: 15rem;
    padding-left: 5rem;
  }
`;

const SidePanelContainer = styled.div`
  grid-area: sidepanel;
  padding: 2rem;
  padding-top: 0rem;

  @media only screen and (min-width: 770px) {
    padding: 0rem 0rem 4rem 4rem;
    position: sticky;
    top: 0;
  }
  @media only screen and (min-width: 1920px) {
    padding-left: 15rem;
  }

  .padding {
    padding: 1rem 0rem;
  }
`;

const ListItemLabel = styled(ListItemText)`
  color: ${props => props.color};
  .MuiListItemText-primary {
    font-weight: bold;
  }
`;

const MenuHeader = styled.div`
  border-bottom: solid 2px black;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 770px) {
    margin-top: 4em;
  }
`;

const MiniCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${props => props.color};
  margin-right: 1rem;
  margin-left: 0.5rem;
`;

const SubMenuItem = styled(ListItemText)`
  padding-left: 2em;
`;

export {
  MarketplaceGrid,
  HeroContainer,
  SDGPanelGrid,
  SidePanelContainer,
  MenuHeader,
  MiniCircle,
  SubMenuItem,
  ListItemLabel,
  MainContentContainer
};
