import styled from "styled-components";
import { OneTwoColumn } from "../shared/StyledComponents";

const AboutContainer = styled.div`
  display: grid;
  grid-template-areas:
    "hero"
    "aboutus"
    "missionvision"
    "main"
    "approach"
    "gettingstarted"
    "footer";
`;

const SplitPanel = styled.div`
  grid-area: ${props => props.gridArea};
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr;
  background-color: #d8d8d8;
  @media only screen and (min-width: 770px) {
    grid-template-columns: 1fr 1fr;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4em;
  }

  button {
    border-radius: 4px;
    border: solid 1px #4371cc;
    color: #4371cc;
    background: transparent;
    width: 50%;
    height: 3rem;

    a {
      text-decoration: none;
    }
  }
`;

const MissionVision = styled.div`
  grid-area: missionvision;
  background: ${props => props.theme.palette.primary.main};
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  padding: 4em;

  @media only screen and (min-width: 770px) {
    grid-template-columns: 1fr 1fr;
    padding: 6rem 4.5rem;
  }

  div {
    display: grid;
    grid-gap: 1em;
  }
`;

const MainContentContainer = styled(OneTwoColumn)`
  grid-area: main;
`;

export { AboutContainer, SplitPanel, MissionVision, MainContentContainer };
