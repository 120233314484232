import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { SUBMITCONTACTFORM } from "../../utilities/mutations";
import { Cookies } from "react-cookie-consent";
import {
  Typography,
  FormGroup,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { CTAButton, PNSemiBold } from "../shared/StyledComponents";
import Loading from "../Loading";
import UserAgreements from "../shared/UserAgreements";

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  padding: 3rem;

  @media only screen and (min-width: 770px) {
    grid-template-columns: 1fr 1fr;
    padding: 5rem 12rem;
  }
  & .left-text {
    text-align: center;

    @media only screen and (min-width: 770px) {
      text-align: left;
      padding-right: 5rem;
    }
  }
  & .contact-form {
    display: grid;
    grid-gap: 1rem;

    > div:first-child {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
    }
  }
`;

function Contact({ client }) {
  const [newContact, { error, loading, data }] = useMutation(
    SUBMITCONTACTFORM,
    { client }
  );
  const [message, setMessage] = useState();
  const [checkedState, setCheckedState] = React.useState({
    gdpr: false,
    marketing: false
  });

  const handleChange = name => event => {
    setCheckedState({ ...checkedState, [name]: event.target.checked });
  };
  const handleSubmit = e => {
    const { email, firstName, lastName, message, contactType } = e.target;

    const acceptedCookie = Cookies.get("CookieConsent");
    // if (!acceptedCookie) {
    //   setMessage("Please accept our use of cookies before proceeding.");
    // } else {
    newContact({
      variables: {
        formDetails: {
          email: email.value,
          source: window.location.href,
          firstName: firstName.value,
          lastName: lastName.value,
          message: message.value,
          gdprAcceptance: Boolean(acceptedCookie),
          marketingOptIn: checkedState.marketing,
          tags: [contactType.value]
        }
      }
    });
    setMessage(null);
    // }
  };
  return (
    <ContactContainer>
      <div className="left-text">
        <Typography variant="h1">Get in touch.</Typography>
        <Typography variant="body1">
          We can help you make the world a better place.
        </Typography>
      </div>

      {data ? (
        <Typography variant="h4" style={{ margin: "1.5rem 0rem" }}>
          Success! We'll be in touch.
        </Typography>
      ) : (
        <FormGroup>
          {error && (
            <Typography variant="h5">
              There was an error in submitting your information. Please try
              again.
            </Typography>
          )}
          {message && <PNSemiBold variant="h5">{message}</PNSemiBold>}
          <form
            className="contact-form"
            onSubmit={e => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div>
              <TextField
                type="text"
                name="firstName"
                label="First Name"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                type="text"
                name="lastName"
                label="Last Name"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
            <TextField
              type="email"
              name="email"
              label="Email"
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
            />
            <RadioGroup
              name="contactType"
              required
              aria-label="contactType"
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <FormControlLabel
                value="B2B"
                control={<Radio />}
                label="Corporate"
              />
              <FormControlLabel
                value="B2C"
                control={<Radio />}
                label="Individual"
              />
            </RadioGroup>
            <TextField
              type="text"
              name="message"
              label="Message"
              margin="normal"
              variant="outlined"
              multiline
              InputLabelProps={{
                shrink: true
              }}
            />
            <UserAgreements
              handleChange={handleChange}
              checkedState={checkedState}
            />
            {loading ? (
              <Loading />
            ) : (
              <CTAButton type="submit" width="106px">
                Submit
              </CTAButton>
            )}
          </form>
        </FormGroup>
      )}
    </ContactContainer>
  );
}

export default withApollo(Contact);
