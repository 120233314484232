import React from "react";
import { Switch, Route, withRouter, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import GoogleAnalyticsListener from "./components/GoogleAnalyticsListener";
import PageLayout from "./routes/PageLayout";
import ErrorView from "./components/ErrorView";
import About from "./components/About";
import Marketplace from "./components/Marketplace";
import Profile from "./components/Profile";
import Submit from "./routes/Submit";
import Admin from "./components/Admin";
import Contact from "./components/Contact";
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import InputDashboard from "./components/InputApp/InputDashboard";
import EditEvent from "./components/InputApp/EditEvent";
import "./app.scss";
import { useAuth0 } from "./components/Auth0Provider";
import MissionPage from "./components/MissionPage";
import HowItWorks from "./components/HowItWorks";
import Corporate from "./components/Corporate";
import ErrorBoundary from "./components/ErrorBoundary";
import PurchaseReport from "./components/PurchaseReport";
import ProfileMissions from "./components/ProfileMissions";
import PolicyPage from "./components/PolicyPage";

function App() {
  const { loading } = useAuth0();

  if (loading) return <Loading  />;

  return (
    <ErrorBoundary>
      <GoogleAnalyticsListener />
      <CookieConsent>
        This site uses cookies to provide you with a great user experience. By
        using {window.location.origin}, you accept our{" "}
        <Link to="/cookies">use of cookies</Link>.
      </CookieConsent>
      <Switch>
        <Route exact path="/signup" component={MissionPage} />
        <Route
          exact
          path="/"
          render={props => (
            <PageLayout
              {...props}
              render={props => <Marketplace {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/about"
          render={props => (
            <PageLayout {...props} render={props => <About {...props} />} />
          )}
        />
        <Route
          exact
          path="/contact"
          render={props => (
            <PageLayout {...props} render={props => <Contact {...props} />} />
          )}
        />
        <Route
          exact
          path="/howitworks"
          render={props => (
            <PageLayout
              {...props}
              render={props => <HowItWorks {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/corporate"
          render={props => (
            <PageLayout {...props} render={props => <Corporate {...props} />} />
          )}
        />
        <PrivateRoute
          exact
          path="/events/dashboard"
          render={props => (
            <PageLayout
              {...props}
              render={props => <InputDashboard {...props} />}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/events/edit"
          render={props => (
            <PageLayout {...props} render={props => <EditEvent {...props} />} />
          )}
        />
        <Route exact path="/events/:tokenRoute" component={MissionPage} />
        <PrivateRoute
          exact
          path="/profile"
          render={props => (
            <PageLayout {...props} render={props => <Profile {...props} />} />
          )}
        />
        <PrivateRoute
          exact
          path="/events/:tokenRoute/submit"
          component={Submit}
        />
        <PrivateRoute
          exact
          path="/admin"
          render={props => (
            <PageLayout {...props} render={props => <Admin {...props} />} />
          )}
        />
        <Route
          exact
          path="/mission"
          render={props => (
            <PageLayout
              {...props}
              render={props => <MissionPage {...props} />}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/profile/missions"
          render={props => (
            <PageLayout
              {...props}
              render={props => <ProfileMissions {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/profile/missions/orders/:orderId"
          render={props => (
            <PageLayout
              {...props}
              render={props => <PurchaseReport {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/profile/missions/purchases/:purchaseId"
          render={props => (
            <PageLayout
              {...props}
              render={props => <PurchaseReport {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/terms"
          render={props => (
            <PageLayout
              {...props}
              render={props => <PolicyPage {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/privacy"
          render={props => (
            <PageLayout
              {...props}
              render={props => <PolicyPage {...props} />}
            />
          )}
        />
        <Route
          exact
          path="/cookies"
          render={props => (
            <PageLayout
              {...props}
              render={props => <PolicyPage {...props} />}
            />
          )}
        />
        <Route
          render={() => <PageLayout render={() => <ErrorView notFound />} />}
        />
      </Switch>
    </ErrorBoundary>
  );
}

export default withRouter(App);
