import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { SUBMITTEDEVENTS } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import {
  SUBMITEVENT,
  APPROVESUBMITTEDEVENT,
  DENYSUBMITTEDEVENT
} from "../../utilities/mutations";
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';

const customColumnSort = (rows, field, direction) => {
  const handleField = row => {
    if (row[field]) {

      if (field === 'seller') {
        return (row.seller.profileData ? row.seller.profileData.name : row.seller.username).toLowerCase();
      }
      
      if (field === 'event') {
        return row.token.name.toLowerCase();
      }

      return typeof row[field] === 'string' ? row[field].toLowerCase() : row[field];
    }

    return row[field];
  };

  return orderBy(rows, handleField, direction);
};

const useStyles = makeStyles(theme => ({
  button: {
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "5px",
    width: "100px",
    textAlign: "center",
    margin: theme.spacing(1)
  }
}));

const customStyles = {
  tableWrapper: {
    style: {
      display: 'block',
    },
  },
}


function DashboardTable({ admin, submittedEvents, totalEvents, client, handlePerRowsChange, handlePageChange, handleSort, totalRows, perPage, ...props }) {
  const { button } = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInner, setDialogInner] = useState("");
  const [approveSubmittedEvent, approveReturn] = useMutation(
    APPROVESUBMITTEDEVENT,
    {
      client,
      refetchQueries: [{ query: SUBMITTEDEVENTS }]
    }
  );
  const [denySubmittedEvent, denyReturn] = useMutation(DENYSUBMITTEDEVENT, {
    client,
    refetchQueries: [{ query: SUBMITTEDEVENTS }]
  });
  const [submitEvent, submitReturn] = useMutation(SUBMITEVENT, {
    client,
    refetchQueries: [{ query: SUBMITTEDEVENTS }]
  });
  if (approveReturn.loading || denyReturn.loading || submitReturn.loading)
    return <Loading />;
  if (approveReturn.error || denyReturn.error || submitReturn.error)
    return (
      <ErrorView
        error={approveReturn.error || denyReturn.error || submitReturn.error}
      />
    );

  const handleEditButton = (row) => {
    props.history.push({
      pathname: `/events/edit`,
      state: { submittedEvent: row }
    })
  }

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      maxWidth: '100px'
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      maxWidth: '100px'
    },
    {
      name: 'Date',
      sortable: true,
      selector: 'timestamp',
      format: ({timestamp}) => new Date(timestamp).toString()
    },
    {
      name: 'Location',
      cell: ({latitude, longitude}) => (
        <a
          href={`http://maps.google.com/?q=${latitude},${longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {latitude}, {longitude}
        </a>
      ),
    },
    {
      name: 'Verification Document',
      cell: ({verificationDoc}) => (
        <Button
                      className={button}
                      onClick={() => {
                        const verifDoc = verificationDoc.replace(
                          /\/ipfs\//g,
                          "https://ipfs.poip.io/ipfs/"
                        );

                        setDialogInner(verifDoc);
                        setDialogOpen(true);
                      }}
                    >
                      Report
                    </Button>
      )
    },
    {
      name: 'Seller',
      sortable: true,
      selector: 'seller',
      format: ({seller}) => seller.profileData ? seller.profileData.name : seller.username,
      wrap: true
    },
    {
      name: 'Event',
      sortable: true,
      selector: 'event',
      cell: ({token}) => (<Link to={`/events/${token.route}`}>{token.name}</Link>),
      wrap: true
    },
    {
      name: 'Status',
      sortable: true,
      selector: 'status',
      cell: (row) => {
        const { status, id } = row;
        return (
          admin ? (
            status === "PENDING" ? (
              <>
                <Button
                  className={button}
                  onClick={() =>
                    approveSubmittedEvent({
                      variables: { id }
                    })
                  }
                >
                  Approve
                </Button>
                <Button
                  className={button}
                  onClick={() =>
                    denySubmittedEvent({
                      variables: { id }
                    })
                  }
                >
                  Deny
                </Button>
              </>
            ) : (
              status
            )
          ) : status === "WORKING" ? (
            <>
              <Button
                className={button}
                onClick={(data) => {
                  handleEditButton(data)
                }}
              >
                Edit
              </Button>
              <Button
                className={button}
                onClick={() =>
                  submitEvent({
                    variables: { id }
                  })
                }
              >
                Submit
              </Button>
            </>
          ) : (
            status
          )
        )
      }
    }
  ];

  return (
    <>
      {submittedEvents.length !== 0 && <DataTable
        columns={columns}
        data={submittedEvents}
        sortFunction={customColumnSort}
        highlightOnHover
        striped
        pagination
        paginationServer
        paginationTotalRows={totalEvents}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        customStyles={customStyles}
      />}
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll="body"
      >
        <DialogActions>
          <IconButton onClick={() => setDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          {/* TODO: figure out a safer solution that doesn't use innerhtml */}
          <div dangerouslySetInnerHTML={{ __html: dialogInner }} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withApollo(DashboardTable);
