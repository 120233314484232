import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

import history from "../utilities/history";
import backendUrl from "../utilities/envBackendUrls";

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

const onRedirectCallback = appState => {
  history.push({ pathname: appState.targetPath });
};

let initOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "dev-27dqwjaq.eu.auth0.com",
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "HjV3jUuUnbH7frVGm4FSBEPUv93tTPNL",
  redirect_uri: window.location.origin,
  onRedirectCallback,
  audience: backendUrl()
};
let client;

const getAuth0Client = () => {
  return new Promise(async (resolve, reject) => {
    if (!client) {
      try {
        client = await createAuth0Client(initOptions);
        resolve(client);
      } catch (e) {
        reject(new Error("getAuth0Client Error", e));
      }
    } else {
      resolve(client);
    }
  });
};

// don't allow multiple getTokenSilently's to be fired off at once
// if one is already pending, use that one
let tokenPromise;
export const getTokenSilently = async () => {
  if (!client) {
    await getAuth0Client();
  }
  if (!tokenPromise) {
    tokenPromise = client.getTokenSilently();
  }
  let token;
  try {
    token = await tokenPromise;
  } catch {
    token = null;
  }
  tokenPromise = null;
  return token;
};

export const Auth0Provider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const client = await getAuth0Client(initOptions);
      setAuth0(client);
      if (window.location.search.includes("code=")) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuthenticated = await client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await client.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
