import styled from "styled-components";
import { Typography, Button } from "@material-ui/core";
import GraphImg from "graphcms-image";

const BoldFont = styled(Typography)`
  font-weight: bold;
`;

const GridGap = styled.div`
  display: grid;
  grid-gap: ${props => props.gap || "1rem"};
`;

const CTAButton = styled(Button)`
  background: #4371cc;
  align-self: center;
  color: white;
  padding: 0.5em 2em;
  margin: ${props => props.margin || "0rem"};
  font-family: "proxima_nova_abold";
  font-size: 1.286rem;
  text-transform: none;
  line-height: 22px;
  height: ${props => props.height || "3rem"};
  width: ${props => props.width || "20rem"};

  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: white;
  }
`;

const TransparentButton = styled.button`
  border-radius: 4px;
  border: solid 1px #4371cc;
  color: #4371cc;
  background: transparent;
  width: ${props => props.width || "100%"};
  max-width: ${props => props.maxWidth || "100%"};
  height: ${props => props.height || "3rem"};
  text-transform: none;
  font-family: "inconsolatabold";
  margin: ${props => props.margin || "0rem"};
`;

const RoundedButton = styled.button`
  width: 3.5rem;
  height: fit-content;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.2px;
  color: #4371cc;
  font-family: "proxima_nova_abold";
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 770px) {
    width: 6.5rem;
    height: 2.8rem;
  }
`;

const Divider = styled.div`
  height: ${props => props.height || "4px"};
  width: ${props => props.width || "100%"};
  background-color: ${props => props.color || "black"};
`;

const Banner = styled.div`
  grid-area: hero;
  display: grid;
  position: relative;
  grid-template-columns: repeat(8, 1fr);
  height: 540px;

  .banner-text {
    grid-column: 3 / span 4;
    position: absolute;
    z-index: 2;
    align-self: center;
    display: grid;
    justify-self: center;
    text-align: center;
  }

  .graphcms-image-outer-wrapper {
    grid-column: 1/-1;
  }
`;

const PNRegular = styled(Typography)`
  font-weight: normal;
`;

const PNSemiBold = styled(Typography)`
  font-weight: 600;
`;

const PNBold = styled(Typography)`
  font-weight: bold;
`;

const InconsolataBold = styled(Typography)`
  font-family: "inconsolatabold";
`;

const Inconsolata = styled(Typography)`
  font-family: "inconsolataregular";
`;

const OneTwoColumn = styled.div`
  display: grid;
  padding: 2rem 1rem;
  grid-gap: 4rem;
  text-align: center;

  @media only screen and (min-width: 770px) {
    padding: 5rem 14rem;
    text-align: left;
  }

  h3 {
    padding-bottom: 1rem;
  }
  .one-two-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @media only screen and (min-width: 770px) {
      grid-template-columns: 1fr 2fr;
    }
  }

  .right-content {
    display: grid;
    grid-template-columns: 1fr;
    border-top: solid 2px black;
    grid-gap: 2.5em;
    padding: 4em 0em;
    margin-top: 1.5rem;
    div {
      display: grid;
      grid-gap: 1em;
    }
    .bottom-row {
      grid-column: 1/-1;
    }
    @media only screen and (min-width: 770px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const StyledImg = styled.img`
  height: ${props => props.height || "269px"};
  width: 100%;
  border-bottom: 5px solid ${props => props.borderColor};
  @media only screen and (min-width: 770px) {
    height: ${props => props.height || "269px"};
  }
`;

const StyledGraphImg = styled(GraphImg)`
  height: ${props => props.height || "269px"};
  width: 100%;
  border-bottom: 5px solid ${props => props.borderColor};
  @media only screen and (min-width: 770px) {
    height: ${props => props.height || "269px"};
  }
`;

export const BannerGraphImg = styled(GraphImg)`
  height: 540px;
`;

export {
  BoldFont,
  CTAButton,
  RoundedButton,
  TransparentButton,
  Banner,
  PNRegular,
  PNSemiBold,
  PNBold,
  Inconsolata,
  InconsolataBold,
  GridGap,
  OneTwoColumn,
  Divider,
  StyledImg,
  StyledGraphImg
};
