import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache, CachePersistor } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";

import history from "../utilities/history";
import theme from "../theme";
import GlobalStyles from "../global";
import App from "../App";

import { Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { StylesProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Storage from "../utilities/storage";

import { from } from "apollo-link";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { getTokenSilently, Auth0Provider } from "./Auth0Provider";
import backendUrl from "../utilities/envBackendUrls";
import ScrollToTop from "./ScrollToTop";
import { RetryLink } from "apollo-link-retry";

const SCHEMA_VERSION = "1"; // Must be a string.
const SCHEMA_VERSION_KEY = "apollo-schema-version";

let token;
const authMiddleware = setContext(async (operation, { headers }) => {
  if (!token) {
    token = await getTokenSilently();
  }
  const authHeader = token ? { authorization: `Bearer ${token}` } : {};
  return {
    headers: {
      ...headers,
      ...authHeader
    }
  };
});
const cache = new InMemoryCache({});

export class AppRoot extends Component {
  state = {
    client: null,
    loaded: false
  };

  async setupApollo() {
    const persistor = new CachePersistor({
      cache,
      storage: Storage
    });

    // Read the current schema version from Storage.
    const currentVersion = await Storage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      // If the current version matches the latest version,
      // we're good to go and can restore the cache.
      await persistor.restore();
    } else {
      // Otherwise, we'll want to purge the outdated persisted cache
      // and mark ourselves as having updated to the latest version.
      process.env.NODE_ENV === "development" &&
        console.log("purging apollo store");
      await persistor.purge();
      await Storage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }

    // Continue setting up Apollo as usual.
  }

  async componentDidMount() {
    const cache = new InMemoryCache({});

    // Setup your Apollo Link, and any other Apollo packages here.
    await this.setupApollo();

    const client = new ApolloClient({
      link: from([
        authMiddleware,
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
              console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          if (networkError)
            console.error(
              `[Network error]: ${networkError} ${networkError.message}`
            );
        }),
        new RetryLink({
          delay: {
            initial: 300,
            max: Infinity,
            jitter: true
          },
          attempts: {
            max: 5,
            retryIf: (error, _operation) => !!error
          }
        }),
        createUploadLink({
          uri: backendUrl()
        })
      ]),
      cache,
      defaultOptions: {
        query: {
          fetchPolicy: "cache-and-network"
        },
        watchQuery: {
          fetchPolicy: "cache-and-network"
        }
      }
    });

    try {
      // See above for additional options, including other storage providers.
      await persistCache({
        cache,
        storage: window.localStorage
      });
    } catch (error) {
      console.error("Error restoring Apollo cache", error);
    }

    this.setState({
      client,
      loaded: true
    });
  }

  render() {
    const { client, loaded } = this.state;

    if (!loaded) {
      return <div>Loading...</div>;
    }

    return (
      <Router history={history}>
        <ScrollToTop />
        <Auth0Provider>
          <ApolloProvider client={client}>
            <GlobalStyles />
            <StylesProvider injectFirst>
              <MuiThemeProvider theme={theme}>
                <StyledThemeProvider theme={theme}>
                  <CssBaseline />
                  <App />
                </StyledThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>
          </ApolloProvider>
        </Auth0Provider>
      </Router>
    );
  }
}
