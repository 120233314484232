import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import SubHeader from "../Profile/SubHeader";
import { PURCHASES } from "../../utilities/queries";
import { useAuth0 } from "../Auth0Provider";

function BuyerMissions({ client, ...props }) {
  const { user } = useAuth0();
  const { loading, error, data } = useQuery(PURCHASES, {
    variables: { buyer: user["https://poip.io/username"] },
    client
  });
  if (loading && !data) return <Loading />;
  if (error) return <ErrorView error={error} />;
  const missionsByOrder = {};
  data.purchases.forEach(purchase => {
    if (purchase.orderId) {
      if (missionsByOrder[purchase.orderId]) {
        missionsByOrder[
          purchase.orderId
        ].impact += `, ${purchase.amount} ${purchase.event.token.name}`;
        missionsByOrder[purchase.orderId].value +=
          purchase.price * purchase.amount * 1.1;
      } else {
        missionsByOrder[purchase.orderId] = {
          orderId: purchase.orderId.slice(20),
          impact: `${purchase.amount} ${purchase.event.token.name}`,
          date: purchase.timestamp,
          value: purchase.price * purchase.amount * 1.1
        };
      }
    } else {
      missionsByOrder[purchase.id] = {
        purchaseId: purchase.id,
        impact: `${purchase.amount} ${purchase.event.token.name}`,
        date: purchase.timestamp,
        value: purchase.price * purchase.amount * 1.1
      };
    }
  });
  const missions = Object.values(missionsByOrder).sort(
    (a, b) => b.timestamp - a.timestamp
  );
  return (
    <Grid container style={{ marginBottom: "25px", flexGrow: 1 }}>
      <div style={{ height: "50px" }} />
      <SubHeader />
      <Grid container style={{ marginTop: "44px" }}>
        <Grid item xs={12} sm={5}>
          <Container>
            <Box bgcolor="black" width="85%" height="4px" marginTop="45px" />
            <Typography
              variant="subtitle1"
              style={{ marginTop: "40px", marginBottom: "20px" }}
            >
              Proof of Purchase
            </Typography>
            <Typography variant="h2">Your Transactions</Typography>
          </Container>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box flexGrow={1} bgcolor="#e0dcd5" height="110%">
            <Container style={{ padding: "50px" }}>
              <Table>
                <TableBody>
                  <TableRow style={{ borderBottom: "solid 2px #16161a" }}>
                    <TableCell>
                      <Typography variant="subtitle1">
                        Mission Impact
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        Transaction Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Value</Typography>
                    </TableCell>
                  </TableRow>
                  {missions.map((mission, idx) => (
                    <TableRow
                      key={idx}
                      style={{ borderTop: "dotted 2px #16161a" }}
                    >
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/profile/missions/${
                            mission.orderId ? "orders/" : "purchases/"
                          }${mission.orderId || mission.purchaseId}`}
                          color="inherit"
                        >
                          <Typography variant="subtitle1">
                            {mission.impact}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {new Date(mission.date).toDateString()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          ${mission.value.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withApollo(BuyerMissions);
