import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";

import { NEWMANUALPURCHASE } from "../../utilities/mutations";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import Button from "../shared/Button";
import { EVENTS, USERS, TOKENS } from "../../utilities/queries";

function NewPurchase({ client }) {
  const eventsReturn = useQuery(EVENTS, {
    client
  });
  const usersReturn = useQuery(USERS, {
    client
  });
  const tokensReturn = useQuery(TOKENS, {
    client
  });
  const [newManualPurchase, newManualPurchaseReturn] = useMutation(
    NEWMANUALPURCHASE,
    {
      client,
      refetchQueries: [{ query: EVENTS }]
    }
  );
  const queryReturns = [
    eventsReturn,
    usersReturn,
    tokensReturn,
    newManualPurchaseReturn
  ];
  if (queryReturns.some(q => q.error)) {
    return <ErrorView error={queryReturns.find(q => q.error).error} />;
  } else if (queryReturns.some(q => q.loading)) {
    return <Loading />;
  }

  return (
    <>
      <FormGroup>
        <form
          onSubmit={e => {
            e.preventDefault();
            e.target.submitbutton.disabled = true;
            e.target.submitbutton.hidden = true;
            const {
              buyer: { value: buyerUsername },
              token: { value: tokenAddress },
              amount: { value: tokenAmount }
            } = e.target;

            if (tokenAmount <= 0) {
              alert("No purchases or commitments created. Check your inputs");
              return;
            }

            let amountRemaining = tokenAmount;
            let purchases = [];
            let commitment;
            const events = eventsReturn.data.events.filter(
              e =>
                e.token.address === tokenAddress &&
                e.productVariant.availableForSale &&
                e.productVariant.inventoryQuantity > 0
            );
            events.sort((a, b) => a.timestamp > b.timestamp);
            for (const event of events) {
              const amount = Math.min(
                amountRemaining,
                event.productVariant.inventoryQuantity
              );
              purchases.push({
                variantId: event.variantId,
                amount
              });
              amountRemaining -= amount;
              if (amountRemaining <= 0) break;
            }
            if (amountRemaining > 0) {
              commitment = { amount: amountRemaining, tokenAddress };
            }
            if (!commitment && !purchases.length) {
              alert("No purchases or commitments created. Check your inputs");
              return;
            }
            newManualPurchase({
              variables: { purchases, commitment, buyer: buyerUsername }
            });
          }}
        >
          <FormControl>
            <InputLabel>Buyer</InputLabel>
            <NativeSelect name="buyer">
              {usersReturn.data.users.map((user, idx) => (
                <option key={idx} value={user.username}>
                  {user.username}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl>
            <InputLabel>Event</InputLabel>
            <NativeSelect name="token">
              {tokensReturn.data.tokens.map((token, idx) => (
                <option key={idx} value={token.address}>
                  {token.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <TextField
            type="number"
            name="amount"
            variant="outlined"
            label="Amount"
            margin="normal"
          />
          <Button type="submit" name="submitbutton">
            Enter New Manual Purchase
          </Button>
        </form>
      </FormGroup>
      {newManualPurchaseReturn.data && "Success!"}
    </>
  );
}

export default withApollo(NewPurchase);
