import React from "react";
import Link from "@material-ui/core/Link";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import styled from "styled-components";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/images/linkedin-icon.svg";

const Icon = styled.div`
  svg {
    width: ${props => props.width || "1rem"};
    height: ${props => props.height || "1rem"};
    > g {
      fill: ${props => props.color || "white"};
    }
  }
`;

function POISocial(props) {
  const { color, width = "1em", height = "1em", border } = props;
  const borderStyles = border ? `solid 1px ${border}` : "none";
  return (
    <>
      <Link
        href="https://twitter.com/proofofimpact"
        target="_blank"
        rel="noopener noreferrer"
        style={{ borderRight: borderStyles, padding: ".5em" }}
      >
        <FaTwitter color={color} style={{ width, height }} />
      </Link>
      <Link
        href="https://instagram.com/proofofimpact"
        target="_blank"
        rel="noopener noreferrer"
        style={{ borderRight: borderStyles, padding: ".5em" }}
      >
        <FaInstagram color={color} style={{ width, height }} />
      </Link>
      <Link
        href="https://linkedin.com/company/proofofimpact"
        target="_blank"
        rel="noopener noreferrer"
        style={{ borderRight: borderStyles, padding: ".5em" }}
      >
        <Icon color={color} height={height} width={width}>
          <LinkedInIcon />
        </Icon>
      </Link>
      <Link
        href="https://www.facebook.com/prproofofimpact/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: ".5em" }}
      >
        <Icon color={color} height={height} width={width}>
          <FacebookIcon />
        </Icon>
      </Link>
    </>
  );
}

export default POISocial;
