import React, { useState } from "react";
import { AppBar, Toolbar, Container, Tabs, Tab } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";

import headerLogo from "../assets/images/ripple-header-logo.svg";
import mobileLogo from "../assets/images/poi-logo-icon.svg";
import { useAuth0 } from "./Auth0Provider";

const StyledTab = styled(Tab)`
  font-family: "proxima_nova_asemibold";
  text-transform: none;
  font-size: 1em;
  text-decoration: inherit;
  min-width: auto;

  .MuiTab-wrapper {
    color: black;
    :hover {
      opacity: 60%;
    }
  }
`;

function Header(props) {
  const { user, loginWithRedirect } = useAuth0();
  const isDesktop = useMediaQuery("(min-width:960px)");
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const links = [
    <StyledTab key={1} component={Link} to="/" label="Marketplace" />,
    <StyledTab
      key={2}
      component={Link}
      to="/howitworks"
      label="How it Works"
    />,
    <StyledTab key={3} component={Link} to="/about" label="About" />,
    <StyledTab key={4} component={Link} to="/corporate" label="Corporate" />,
    <StyledTab key={5} component={Link} to="/contact" label="Contact" />
  ];

  const profileTab =
    process.env.REACT_APP_ALLOW_DONATIONS !== "true"
      ? [<StyledTab key={7} component={Link} to="/signup" label={"Sign up"} />]
      : user
      ? [
          <StyledTab
            key={6}
            component={Link}
            to={
              user["https://poip.io/roles"][0] === "ADMIN"
                ? "/admin"
                : "/profile"
            }
            label={
              user["https://poip.io/roles"][0] === "ADMIN"
                ? "Admin"
                : "My Impact"
            }
          />
        ]
      : [
          <StyledTab
            key={6}
            onClick={() =>
              loginWithRedirect({
                appState: { targetPath: window.location.pathname }
              })
            }
            label={"Log in"}
          />,
          <StyledTab key={7} component={Link} to="/signup" label={"Sign up"} />
        ];

  return (
    <>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white", height: "48px", color: "black" }}
        elevation={4}
      >
        <Container>
          <Toolbar variant="dense">
            {isDesktop ? (
              <>
                <Link to="/">
                  <img
                    src={headerLogo}
                    alt="logo"
                    style={{ maxHeight: "34px" }}
                  />
                </Link>
                <div style={{ flexGrow: 1 }} />
                <Tabs
                  value={false}
                  variant="scrollable"
                  scrollButtons="auto"
                  orientation="horizontal"
                >
                  {links.concat(profileTab)}
                </Tabs>
              </>
            ) : (
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <Link to="/">
                    <img
                      src={mobileLogo}
                      alt="logo"
                      style={{
                        maxHeight: "34px"
                      }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Tabs
                    value={false}
                    variant="scrollable"
                    scrollButtons="auto"
                    orientation="horizontal"
                    style={{ display: "block" }}
                  >
                    {profileTab}
                  </Tabs>
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {!isDesktop && (
        <nav>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            PaperProps={{
              style: {
                width: "170px"
              }
            }}
            onClick={handleDrawerToggle}
          >
            <Tabs
              value={false}
              variant="scrollable"
              scrollButtons="auto"
              orientation="vertical"
            >
              {links}
            </Tabs>
          </Drawer>
        </nav>
      )}
    </>
  );
}

export default withRouter(Header);
