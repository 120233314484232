import React from "react";

import BuyerDashboard from "./BuyerDashboard";
import SellerDashboard from "./SellerDashboard";
import ErrorView from "../ErrorView";
import { useAuth0 } from "../Auth0Provider";

function Profile(props) {
  const { user } = useAuth0();
  if (!user) return <ErrorView />;
  if (user["https://poip.io/roles"][0] === "BUYER")
    return <BuyerDashboard {...props} />;
  else if (user["https://poip.io/roles"][0] === "SELLER")
    return <SellerDashboard {...props} />;
  return <ErrorView />;
}

export default Profile;
