import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-129180280-2");
}

class GoogleAnalyticsListener extends React.Component {
  constructor(props) {
    super(props);
    if (process.env.NODE_ENV === "production") {
      this.props.history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalyticsListener);
