import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import { SUBMITEVENT, CREATEEVENT } from "../../utilities/mutations";
import { createEventHandler } from "../../utilities/inputAppUtils";

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2)
  },
  button: {
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "5px",
    width: "200px",
    maxHeight: "50px",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(4)
  },
  image: {
    width: "100px",
    height: "100px",
    margin: theme.spacing(4)
  }
}));

function EditEvent({ client, ...props }) {
  const classes = useStyles();
  const { submittedEvent } = props.location.state;
  const {
    metadata: { document },
    id
  } = submittedEvent;

  const initialState = document.reduce((state, data) => {
    state[data.stateVar] = data.value;
    return state;
  }, {});
  const [values, setValues] = useState(initialState);
  const [
    createEvent,
    { loading: createLoading, error: createError }
  ] = useMutation(CREATEEVENT, { client });
  const [submitEvent, { loading, error }] = useMutation(SUBMITEVENT, {
    client
  });

  if (loading || createLoading) return <Loading />;
  if (error || createError) return <ErrorView error={error || createError} />;

  return (
    <>
      <form
        style={{ display: "flex" }}
        onSubmit={async () => {
          await createEvent({
            variables: {
              id,
              ...createEventHandler(
                values,
                document,
                submittedEvent.token.address
              )
            }
          });
          await submitEvent({ variables: { id } });
          props.history.push("/events/dashboard");
        }}
      >
        <Grid style={{ padding: "50px" }}>
          <Grid item xs={12} lg={12}>
            <Typography variant="h4">Impact Event Details</Typography>
            <br />
            <Typography variant="body1">
              Fields marked with * are required before final submission.
            </Typography>
          </Grid>

          <Grid item>
            <Button
              className={classes.button}
              onClick={() => props.history.push("/events/dashboard")}
            >
              Back to Panel
            </Button>
          </Grid>
          {document.map((dataPoint, i) => {
            const {
              default: InputComponent
            } = require(`./${dataPoint.component}`);
            return (
              <Grid item key={`edit-${i}`}>
                <InputComponent
                  details={dataPoint}
                  setValues={setValues}
                  values={values}
                  edit
                />
              </Grid>
            );
          })}
          <Grid item>
            <Grid container justify="center" style={{ marginTop: "50px" }}>
              <Button
                className={classes.button}
                onClick={() => {
                  createEvent({
                    variables: {
                      id,
                      ...createEventHandler(
                        values,
                        document,
                        submittedEvent.token.address
                      )
                    }
                  });
                }}
              >
                Save
              </Button>
              <Button className={classes.button} type="submit">
                Submit Event
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default withApollo(EditEvent);
