import moment from "moment";

export const schemaToMetadata = components => {
  return components.reduce(
    (
      result,
      {
        component,
        title,
        stateVar,
        defaultState,
        formatter,
        required,
        adornment
      }
    ) => {
      if (
        !["Title", "Submitting", "Review", "Confirmation"].includes(component)
      ) {
        result.push({
          component,
          title,
          stateVar,
          defaultState,
          ...(formatter && { formatter }),
          required,
          adornment
        });
      }
      return result;
    },
    []
  );
};

export const formatters = {
  Geolocation: value => `<a
      href="https://maps.google.com/?q=${value.latitude},${value.longitude}"
      target="_blank"
      rel="noopener noreferrer"
      >${value.latitude}, ${value.longitude}</a>`,
  Image: value =>
    `${value
      .map(dataUrl => `<img src="/ipfs/${dataUrl}" alt="" width="200"/><br />`)
      .join("")}<br />`,
  MultiField: value =>
    `<ul>${Object.keys(value)
      .map(field => `<li>${field} - ${value[field]}</li>`)
      .join("")}</ul>`,
  Place: value => `${value.Place}, ${value.City}, ${value.Country}`,
  DateTime: value => moment(`${value.date} ${value.time}`).toString()
};

export const addValueAndFormatted = (event, components) => {
  return components.map(component => {
    component.value = event[component.stateVar] || component.defaultState;
    const hasFormatter =
      component.formatter &&
      typeof formatters[component.formatter] === "function";
    component.formatted = hasFormatter
      ? formatters[component.formatter](component.value)
      : component.value;
    return component;
  });
};

export const createEventHandler = (event, components, tokenAddress) => {
  const { eventGeolocation, amount, eventDateTime } = event;
  const timestamp = moment(
    `${eventDateTime.date} ${eventDateTime.time}`
  ).toISOString();
  const metadata = addValueAndFormatted(event, components);

  return {
    timestamp,
    amount: parseInt(amount),
    metadata: { document: metadata },
    latitude: eventGeolocation.latitude || "",
    longitude: eventGeolocation.longitude || "",
    tokenAddress
  };
};
