import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import { PURCHASESBYORDER } from "../../utilities/queries";
import Report from "./Report";
import PendingOrder from "./PendingOrder";

function Order({ client, ...props }) {
  const { loading, error, data } = useQuery(PURCHASESBYORDER, {
    variables: { orderId: `gid://shopify/Order/${props.match.params.orderId}` },
    client
  });

  if (loading && !data) return <Loading />;
  if (error) return <ErrorView error={error} />;
  if (!data || !data.purchasesByOrder || !data.purchasesByOrder.length)
    return <PendingOrder {...props} />;

  return <Report purchases={data.purchasesByOrder} />;
}

export default withApollo(Order);
