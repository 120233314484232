import React from "react";
import { useLocation } from "react-router-dom";
import { withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";

import { POLICIES } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

const TableMarkdown = styled(ReactMarkdown)`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
`;

function PolicyPage({ client }) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const location = useLocation();
  const { loading, data, error } = useQuery(POLICIES, { client });

  if (loading && !data) return <Loading />;
  if (error) {
    return <ErrorView error={error} />;
  }

  const source =
    data.policies[0][
      {
        "/terms": "termsAndConditions",
        "/privacy": "privacyPolicy",
        "/cookies": "cookiePolicy"
      }[location.pathname]
    ];

  return (
    <Container
      style={
        isDesktop ? { margin: "50px" } : { argin: "50px", maxWidth: "100vw" }
      }
    >
      <TableMarkdown source={source} />
    </Container>
  );
}

export default withApollo(PolicyPage);
