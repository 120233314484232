import React from "react";
import { Typography, Grid, CircularProgress } from "@material-ui/core";

import ErrorView from "../ErrorView";
import SaveAndNavigateButtons from "./SaveAndNavigateButtons";

function Slide({
  details,
  i,
  total,
  navHandlers,
  saved,
  loading,
  page,
  errors,
  ...props
}) {
  const { title, component, description } = details;
  const { default: InputComponent } = require(`./${component}`);
  const statusMessage = (
    <>
      {loading && <CircularProgress />}
      {errors && <ErrorView error={errors.createError || errors.submitError} />}
      {saved && <h5>Event has been saved.</h5>}
    </>
  );
  return (
    <Grid
      container
      justify="center"
      style={{
        padding: "25% 0%"
      }}
    >
      <Grid
        container
        direction="column"
        justify="center"
        spacing={3}
        className="text-light text-center"
        style={{ width: "90%" }}
      >
        <Grid item>
          <Typography variant="h3">{title}</Typography>
          <br />
          {component !== "Submitting" && statusMessage}
          <br />
          <Typography variant="body1">{description}</Typography>
        </Grid>
        <Grid container item justify="center" spacing={3}>
          {InputComponent && (
            <InputComponent
              details={details}
              loading={loading}
              handlers={navHandlers}
              errors={errors}
              {...props}
            />
          )}
        </Grid>
        {!["Title", "Submitting", "Confirmation"].includes(component) ? (
          <Grid
            container
            item
            justify="center"
            style={{ marginBottom: "100px" }}
          >
            <SaveAndNavigateButtons
              handlers={navHandlers}
              page={page}
              title={title}
              component={component}
              {...props}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid item>
        <div className="carousel-caption">
          {i !== 0 ? (
            <h6>
              Step {i + 1} of {total}
            </h6>
          ) : null}
          <p>
            <small>Powered by Proof of Impact</small>
          </p>
        </div>
      </Grid>
    </Grid>
  );
}

export default Slide;
