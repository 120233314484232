import React from "react";
import EventCard from "../shared/EventCard";

const eventsGrid = events => {
  return events
    .reduce(
      (updateditems, currentItem) => {
        const itemsLength = updateditems.length - 1;
        const isEvenRow = updateditems.length % 2 === 0;
        const itemComponent = <EventCard event={currentItem} />;
        updateditems[itemsLength].length > (isEvenRow ? 2 : 1)
          ? updateditems.push([itemComponent])
          : updateditems[itemsLength].push(itemComponent);
        return updateditems;
      },
      [[]]
    )
    .map((row, i) => {
      return row.map((item, idx) => {
        return (
          <section key={idx} className={(i + 1) % 2 === 0 ? "even" : "odd"}>
            {item}
          </section>
        );
      });
    });
};

export default eventsGrid;
