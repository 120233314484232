import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import { ORDER } from "../../utilities/queries";
import Report from "./Report";

function PendingOrder({ client, ...props }) {
  const { loading, error, data } = useQuery(ORDER, {
    variables: { id: `gid://shopify/Order/${props.match.params.orderId}` },
    client
  });

  if (loading && !data) return <Loading />;
  if (error) return <ErrorView error={error} />;
  if (!data || !data.order) return <ErrorView />;

  return <Report order={data.order} />;
}

export default withApollo(PendingOrder);
