import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { USERS } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

function UserTable({ client }) {
  const { loading, error, data } = useQuery(USERS, {
    client
  });

  return (
    <>
      {error ? (
        <ErrorView error={error} />
      ) : loading ? (
        <Loading />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Public Profile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.users.map((user, idx) => (
              <TableRow key={idx}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.profileData.name}</TableCell>
                <TableCell>{user.profileData.organization}</TableCell>
                <TableCell>{user.profileData.website}</TableCell>
                <TableCell>{user.profileData.phoneNumber}</TableCell>
                <TableCell>
                  {user.profileData.public ? "TRUE" : "FALSE"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default withApollo(UserTable);
