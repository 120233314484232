import React from "react";
import GridList from "@material-ui/core/GridList";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import GridListTile from "@material-ui/core/GridListTile";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import { EVENT } from "../../utilities/queries";

function ReportAccordionSection({ client, purchase, lineItem, startExpanded }) {
  const { loading, error, data } = useQuery(EVENT, {
    variables: !!purchase ? {} : { variantId: lineItem.variant.id },
    client,
    skip: !!purchase
  });

  if (!purchase) {
    if (loading && !data) return <Loading />;
    if (error) return <ErrorView error={error} />;
    if (!data || !data.event) return <ErrorView />;
  }

  let purchaseData = [];
  let eventData = [];
  let imageUrls = [];
  let event;
  if (purchase) {
    event = purchase.event;
    purchaseData.push({
      header: "Impact",
      text: purchase.amount
    });
    purchaseData.push({
      header: "Price",
      text: `$${purchase.price}`
    });
    purchaseData.push({
      header: "Fee",
      text: "10%"
    });
    purchaseData.push({
      header: "Total Cost",
      text: `$${(1.1 * purchase.price * purchase.amount).toFixed(2)}`
    });
    if (purchase.rangeStart !== null) {
      purchaseData.push({
        header: "Impact Inventory",
        text: `${
          purchase.rangeStart !== purchase.rangeEnd
            ? `${purchase.rangeStart} - ${purchase.rangeEnd}`
            : purchase.rangeStart
        } of ${purchase.event.amount}`
      });
    }
  } else {
    event = data.event;
    purchaseData.push({
      header: "Impact",
      text: lineItem.quantity
    });
    purchaseData.push({
      header: "Fee",
      text: "10%"
    });
    purchaseData.push({
      header: "Total Cost",
      text: `$${lineItem.originalTotalSet.presentmentMoney.amount}`
    });
  }

  const header = `${event.amount} ${event.token.name} - ${new Date(
    event.timestamp
  ).toDateString()}`;

  eventData.push({
    header: "Impact Event",
    data: event.token.name
  });
  eventData.push({
    header: "Impact Created",
    data: event.amount
  });
  eventData.push({
    header: "Event Date",
    data: new Date(event.timestamp).toString()
  });
  eventData.push({
    header: "Submission Date",
    data: new Date(event.submitted).toString()
  });
  eventData.push({
    header: "Seller",
    data: event.seller.username
  });
  eventData.push({
    header: "Location",
    data: `<a
    href="https://maps.google.com/?q=${event.latitude},${event.longitude}"
    target="_blank"
    rel="noopener noreferrer"
    >${event.latitude}, ${event.longitude}</a>`
  });
  eventData.push({
    header: "SDGs",
    data: event.token.sdgs.join(", ")
  });

  event.metadata.document.forEach(({ formatted, title }) => {
    formatted = formatted.toString();
    if (formatted.includes("<img") && formatted.includes("/ipfs/")) {
      formatted.split('"').forEach(splitStr => {
        if (splitStr.startsWith("/ipfs/")) {
          imageUrls.push(`https://ipfs.poip.io${splitStr}`);
        }
      });
    } else {
      eventData.push({
        header: title,
        data: formatted
      });
    }
  });

  return (
    <ExpansionPanel defaultExpanded={startExpanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{header}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Box flexDirection="column">
          <Box mt={3} mb={1}>
            <Typography variant="h5">Summary</Typography>
          </Box>
          <Table>
            <TableBody>
              {purchaseData.map(({ header, text }, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {header}
                  </TableCell>
                  <TableCell align="right">{text}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box mt={3} mb={1}>
            <Typography variant="h5">Event Details</Typography>
          </Box>
          <Table>
            <TableBody>
              {eventData.map(({ header, data }, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {header}
                  </TableCell>
                  <TableCell align="right">
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box mt={3} mb={1}>
            <Typography variant="h5">Media</Typography>
          </Box>
          <Box mb={4} mx="auto">
            <GridList cols={3}>
              {imageUrls.map((url, idx) => (
                <GridListTile key={idx}>
                  <img src={url} alt="" />
                </GridListTile>
              ))}
            </GridList>
          </Box>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default withApollo(ReportAccordionSection);
