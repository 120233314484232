import React from "react";
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

const SocialContainer = styled(Grid)`
  .SocialMediaShareButton {
    cursor: pointer;
  }
`;

function SocialShare(props) {
  const { color, header, description } = props;
  return (
    <SocialContainer
      container
      justify="space-between"
      item
      style={{ width: "auto" }}
    >
      <TwitterShareButton simple url={window.location.href} title={header}>
        <TwitterIcon
          iconBgStyle={{ fill: "none" }}
          logoFillColor={color}
          size={35}
        />
      </TwitterShareButton>
      <LinkedinShareButton simple url={window.location.href}>
        <LinkedinIcon
          iconBgStyle={{ fill: "none" }}
          logoFillColor={color}
          size={35}
        />
      </LinkedinShareButton>
      <FacebookShareButton simple url={window.location.href} quote={header}>
        <FacebookIcon
          iconBgStyle={{ fill: "none" }}
          logoFillColor={color}
          size={35}
        />
      </FacebookShareButton>
      <EmailShareButton
        simple
        url={window.location.href}
        subject={header}
        body={description}
      >
        <EmailIcon
          iconBgStyle={{ fill: "none" }}
          logoFillColor={color}
          size={35}
        />
      </EmailShareButton>
    </SocialContainer>
  );
}

export default SocialShare;
