import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth0 } from "../components/Auth0Provider";

function PageLayout({ render, ...otherProps }) {
  const { user } = useAuth0();
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        top: 0,
        left: 0,
        width: "100%",
        display: "grid",
        gridTemplateRows: "1fr auto"
      }}
    >
      <Header {...otherProps} />
      <div
        style={{
          paddingTop: "48px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {render({ ...otherProps, user })}
      </div>
      <Footer />
    </div>
  );
}

export default PageLayout;
