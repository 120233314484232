import React from "react";
import { Query } from "react-apollo";

import { TOKEN } from "../utilities/queries";
import ErrorView from "../components/ErrorView";
import Loading from "../components/Loading";

function GqlSubmit({ render, ...otherProps }) {
  return (
    <Query
      query={TOKEN}
      variables={{ route: otherProps.match.params.tokenRoute }}
    >
      {token => {
        const queries = [token];
        if (queries.some(q => q.error))
          return <ErrorView error={queries.find(q => q.error).error} />;
        if (queries.some(q => q.loading)) return <Loading />;
        let queryProps = {};
        queries.forEach(q => {
          queryProps = { ...queryProps, ...q.data };
        });
        return render(queryProps);
      }}
    </Query>
  );
}

export default GqlSubmit;
