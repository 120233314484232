import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { AdapterNavLink } from "../AdapterLinks";

const FixedHeightPaper = styled(Paper)`
  height: 400px;
  background-color: #e0dcd5;
`;

function AtAGlance({ totalMissions, sdgsContributedTo, biggestAmount }) {
  return (
    <FixedHeightPaper>
      <div
        style={{
          height: "70px",
          width: "100%",
          borderBottom: "solid 1px #16161a"
        }}
      >
        <div style={{ height: "20px" }} />
        <Typography variant="body1" style={{ marginLeft: "30px" }}>
          At a glance
        </Typography>
      </div>
      <div style={{ margin: "40px", marginTop: "20px" }}>
        <Grid
          container
          spacing={3}
          style={{ borderBottom: "dotted 1px #16161a", marginBottom: "15px" }}
        >
          <Grid item xs={3}>
            {totalMissions}
          </Grid>
          <Grid item xs={9}>
            Total Missions
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ borderBottom: "dotted 1px #16161a", marginBottom: "15px" }}
        >
          <Grid item xs={3}>
            {sdgsContributedTo.size}
          </Grid>
          <Grid item xs={9}>
            SDGs contributed to
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ borderBottom: "dotted 1px #16161a", marginBottom: "15px" }}
        >
          <Grid item xs={3}>
            {biggestAmount}
          </Grid>
          <Grid item xs={9}>
            Greatest impact in a single mission
          </Grid>
        </Grid>
      </div>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <Button
          component={AdapterNavLink}
          to="/"
          style={{
            textDecoration: "inherit",
            color: "#4371cc"
          }}
          size="small"
          variant="outlined"
        >
          Marketplace
        </Button>
      </div>
    </FixedHeightPaper>
  );
}

export default AtAGlance;
