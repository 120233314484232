import React from "react";

import { useAuth0 } from "../Auth0Provider";
import ErrorView from "../ErrorView";
import BuyerMissions from "./BuyerMissions";
import SellerMissions from "./SellerMissions";

function ProfileMissions() {
  const { user } = useAuth0();
  if (!user) return <ErrorView />;
  if (user["https://poip.io/roles"][0] === "BUYER") return <BuyerMissions />;
  if (user["https://poip.io/roles"][0] === "SELLER") return <SellerMissions />;
  return <ErrorView />;
}

export default ProfileMissions;
