import React, { useState } from "react";
import {
  Col,
  Row,
  Carousel as CarouselBase,
  CarouselItem,
  CarouselControl
} from "reactstrap";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import styled from "styled-components";

import Loading from "../Loading";
import ErrorView from "../ErrorView";
import { useAuth0 } from "../Auth0Provider";
import { USER, SUBMITTEDEVENTS } from "../../utilities/queries";
import {
  InconsolataBold,
  PNBold,
  TransparentButton,
  Divider,
  PNSemiBold,
  StyledGraphImg,
  StyledImg
} from "../shared/StyledComponents";

const RegisteredEvent = styled(Paper)`
  height: fit-content;
  background-color: #e0dcd5;
  display: grid;
  grid-template-rows: 4.5rem 1fr;
  @media only screen and (min-width: 770px) {
    height: 400px;
  }
  .mission-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row: 2;
    @media only screen and (min-width: 770px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2.5rem;
    border-bottom: solid 1px #16161a;
  }
`;

const Carousel = styled(CarouselBase)`
  .carousel-control-prev,
  .carousel-control-next {
    height: 80%;
    top: 20%;
  }
`;

const MissionData = styled.div`
  padding: 1.5rem 2rem;
  display: grid;
  grid-gap: 1rem;
  height: fit-content;
  .table {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    div {
      display: grid;
      grid-template-columns: 30% 1fr;
      align-items: center;
      border-bottom: dotted 1px #16161a;
    }
  }
`;

function RegisteredEvents({ client }) {
  const { user: loggedIn } = useAuth0();
  const [activeIndex, setActiveIndex] = useState(0);
  const { loading, data, error } = useQuery(USER, {
    client,
    variables: { username: loggedIn["https://poip.io/username"] }
  });
  const submittedEventsReturn = useQuery(SUBMITTEDEVENTS, {
    variables: { seller: loggedIn["https://poip.io/username"] },
    client
  });

  if (loading || submittedEventsReturn.loading) return <Loading />;
  else if (error || submittedEventsReturn.error)
    return <ErrorView error={error || submittedEventsReturn.error} />;

  const { tokens: registeredEvents } = data.user;
  const { submittedEvents } = submittedEventsReturn.data;

  const inProgress = submittedEvents.filter(event => event.status === "WORKING")
    .length;
  const inReview = submittedEvents.filter(event => event.status === "PENDING")
    .length;
  const verified = submittedEvents.filter(event => event.status === "APPROVED")
    .length;

  const next = () =>
    setActiveIndex(
      activeIndex === registeredEvents.length - 1 ? 0 : activeIndex + 1
    );
  const previous = () =>
    setActiveIndex(
      activeIndex === 0 ? registeredEvents.length - 1 : activeIndex - 1
    );

  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
      >
        {registeredEvents.map((event, i) => {
          let { eventPage, sdgs: primarySdgs } = event;
          const borderColor =
            eventPage && eventPage.sdgs
              ? eventPage.sdgs[0].color.hex
              : typeof primarySdgs[0] === "object"
              ? primarySdgs[0].color.hex
              : "white";
          const { color, category } = eventPage.sdgs[0];
          const { displayDescription, displayImage } = eventPage;
          return (
            <CarouselItem key={`registered-event-${i}`}>
              <RegisteredEvent>
                <div className="title">
                  <InconsolataBold variant="body1">
                    Your Impact Mission
                  </InconsolataBold>
                  {process.env.REACT_APP_BACKEND_ENV !== "production" &&
                    loggedIn &&
                    loggedIn["https://poip.io/roles"][0] === "SELLER" && (
                      <Row>
                        <Col>
                          <Link to={`/events/${event.route}/submit`}>
                            <TransparentButton height="32px" width="136px">
                              Submit Data
                            </TransparentButton>
                          </Link>
                        </Col>
                      </Row>
                    )}
                </div>

                <div className="mission-container">
                  {displayImage ||
                  (eventPage && eventPage.eventPageMissionStart) ? (
                    <StyledGraphImg
                      image={
                        displayImage ||
                        eventPage.eventPageMissionStart.backgroundImage
                      }
                      className="d-block w-100"
                      borderColor={borderColor}
                      height={"400px"}
                    />
                  ) : (
                    <StyledImg
                      src={require(`../../assets/images/sdg/sdg_icon_${
                        typeof primarySdgs[0] === "number"
                          ? primarySdgs[0].toString()
                          : primarySdgs[0].number.toString()
                      }.png`)}
                      borderColor={borderColor}
                      height={"400px"}
                      alt="impact event"
                    />
                  )}

                  <MissionData>
                    <Divider
                      color={color.hex}
                      height={"4px"}
                      style={{ "margin-bottom": ".5rem" }}
                    />

                    <PNBold variant="h6" style={{ color: color.hex }}>
                      {category}
                    </PNBold>

                    <PNSemiBold variant="h4">{displayDescription}</PNSemiBold>
                    <div className="table">
                      <div>
                        <PNBold variant="h4">{inProgress}</PNBold>
                        <PNSemiBold variant="body1">
                          Events in progress
                        </PNSemiBold>
                      </div>
                      <div>
                        <PNBold variant="h4">{inReview}</PNBold>
                        <PNSemiBold variant="body1">
                          Events in review
                        </PNSemiBold>
                      </div>
                      <div>
                        <PNBold variant="h4">{verified}</PNBold>
                        <PNSemiBold variant="body1">Events verified</PNSemiBold>
                      </div>
                      <div>
                        <PNBold variant="h4">{submittedEvents.length}</PNBold>
                        <PNSemiBold variant="body1">Total Events</PNSemiBold>
                      </div>
                    </div>
                  </MissionData>
                </div>
              </RegisteredEvent>
            </CarouselItem>
          );
        })}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </>
  );
}

export default withApollo(RegisteredEvents);
