import React from "react";
import { List, ListItem, Grid, Collapse, ButtonBase } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  MenuHeader,
  MiniCircle,
  SubMenuItem,
  SidePanelContainer,
  ListItemLabel
} from "./MarketplaceStyles";
import { PNRegular, PNSemiBold } from "../shared/StyledComponents";
import { filterCategories } from "../../utilities/sdgs";

function SidePanel({ handleFilterMenu, open, selectedIndex }) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  return (
    <SidePanelContainer>
      {isDesktop && (
        <Grid className="padding">
          <MenuHeader>
            <PNSemiBold variant="h6">Make an impact</PNSemiBold>
            <ButtonBase onClick={() => handleFilterMenu(null)}>
              {typeof selectedIndex === "number" ? (
                <PNSemiBold variant="h6">X</PNSemiBold>
              ) : null}
            </ButtonBase>
          </MenuHeader>
          {Object.keys(filterCategories).map((category, i) => {
            return (
              <List key={`menu-list-${i}`} style={{ padding: 0 }}>
                <ListItem
                  button
                  onClick={() => handleFilterMenu(category, i)}
                  style={{ padding: 0 }}
                  selected={selectedIndex === i}
                >
                  <MiniCircle
                    color={filterCategories[category].color}
                  ></MiniCircle>
                  <ListItemLabel
                    primary={category}
                    primaryTypographyProps={{ variant: "h6" }}
                    color={filterCategories[category].color}
                  />
                </ListItem>
                <Collapse in={open[category]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Object.keys(filterCategories[category].sub).map(
                      (goalNumber, i) => (
                        <ListItem
                          button
                          key={i}
                          style={{ padding: 0 }}
                          disabled
                        >
                          <SubMenuItem
                            primary={filterCategories[category].sub[goalNumber]}
                            primaryTypographyProps={{ variant: "subtitle2" }}
                          />
                        </ListItem>
                      )
                    )}
                  </List>
                </Collapse>
              </List>
            );
          })}
        </Grid>
      )}
      <Grid className="padding">
        <MenuHeader>
          <PNSemiBold variant="h6">How it works</PNSemiBold>
        </MenuHeader>
        <PNRegular variant="h6">
          Transactions are backed by individual data and media that links your
          donation dollars to unique, verified outcomes.
        </PNRegular>
        <a href="/howitworks">
          <PNSemiBold variant="h6">Learn more</PNSemiBold>
        </a>
      </Grid>
      <Grid className="padding">
        <MenuHeader>
          <PNSemiBold variant="h6">More reach, more impact</PNSemiBold>
        </MenuHeader>
        <PNRegular variant="h6">Sign up and show your support.</PNRegular>
        <a href="/signup">
          <PNSemiBold variant="h6">Signup</PNSemiBold>
        </a>
      </Grid>
    </SidePanelContainer>
  );
}

export default SidePanel;
