import React from "react";
import * as Sentry from "@sentry/browser";
import ErrorView from "./ErrorView";
import PageLayout from "../routes/PageLayout";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <PageLayout render={() => <ErrorView eventId={this.state.eventId} />} />
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
