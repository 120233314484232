import React from "react";
import { Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import TrendingFlatRoundedIcon from "@material-ui/icons/TrendingFlatRounded";

import { useAuth0 } from "../Auth0Provider";
import oceanBg from "../../assets/images/buyerDash/marek-okon-t-ww-cq-i-mi-umg-unsplash-1@3x.jpg";

const FixedHeightPaper = styled(Paper)`
  height: 400px;
  background-color: #e0dcd5;
`;

function Totals({ totalOutcomes }) {
  const { user } = useAuth0();
  return (
    <FixedHeightPaper
      style={{
        backgroundImage: `url(${oceanBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        textShadow: "0px 0px 8px #000000"
      }}
    >
      <div
        style={{
          height: "70px",
          width: "100%",
          backgroundColor: "rgba(22, 22, 26, 0.6)",
          borderRadius: "4px 4px 0px 0px"
        }}
      >
        <div style={{ height: "20px" }} />
        <Typography
          variant="body1"
          style={{ marginLeft: "30px", color: "white" }}
        >
          Your Impact, {user["https://poip.io/username"]}!
        </Typography>
      </div>
      <div
        style={{
          textAlign: "center",
          color: "white"
        }}
      >
        <div style={{ marginTop: "40px" }}>
          <Typography variant="h2">{totalOutcomes.amount}</Typography>
          <Typography>
            {user["https://poip.io/roles"][0] === "BUYER"
              ? "Your Impact"
              : "total impact delivered"}
          </Typography>
        </div>
        <TrendingFlatRoundedIcon
          style={{ transform: "rotate(90deg)", margin: "20px" }}
        />
        <div>
          <Typography variant="h6">${totalOutcomes.value}</Typography>
          <Typography>
            {user["https://poip.io/roles"][0] === "BUYER"
              ? "You Funded"
              : "total funds generated"}
          </Typography>
        </div>
      </div>
    </FixedHeightPaper>
  );
}

export default Totals;
