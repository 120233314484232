import React from "react";
import { Grid } from "@material-ui/core";
import { BoldFont } from "../shared/StyledComponents";
import eventsGrid from "./EventsGrid";

function FilteredSDG({ events, ...props }) {
  const { filter } = props;
  const filteredList = events.reduce((list, token) => {
    if (token.impactCategory === filter) {
      list.push(token);
    }
    return list;
  }, []);
  const hasEvents = filteredList.length !== 0;
  const noResults = (
    <Grid
      item
      container
      justify="center"
      style={{
        gridColumn: "1/-1"
      }}
    >
      <BoldFont variant="h4">{`${filter} impact is coming soon!`}</BoldFont>
    </Grid>
  );

  return (
    <>
      {hasEvents
        ? eventsGrid(filteredList).map((row, i) => {
            return <React.Fragment key={`filtered-${i}`}>{row}</React.Fragment>;
          })
        : noResults}
    </>
  );
}

export default FilteredSDG;
