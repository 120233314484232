import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { Grid, Container } from "@material-ui/core";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import SubHeader from "./SubHeader";
import { PURCHASES, COMMITDRAWDOWNS, EVENTS } from "../../utilities/queries";
import { useAuth0 } from "../Auth0Provider";
import Totals from "./Totals";
import Graphs from "./Graphs";
import AtAGlance from "./AtAGlance";
// import TypeTotals from "./TypeTotals";
import MissionLocation from "./MissionLocation";
import MissionDetails from "./MissionDetails";
import RegisteredEvents from "../Seller/RegisteredEvents";

const sdgGroups = {
  social: [1, 2, 4, 5, 8, 9, 10, 16, 17],
  health: [3, 6],
  environment: [7, 11, 12, 13],
  animal: [14, 15]
};

function SellerDashboard({ client, ...props }) {
  const { user } = useAuth0();
  const purchasesReturn = useQuery(PURCHASES, {
    client,
    variables: { seller: user["https://poip.io/username"] }
  });
  const drawdownsReturn = useQuery(COMMITDRAWDOWNS, {
    client,
    variables: { seller: user["https://poip.io/username"] }
  });
  const eventsReturn = useQuery(EVENTS, {
    client,
    variables: { seller: user["https://poip.io/username"] }
  });
  if (
    purchasesReturn.loading ||
    drawdownsReturn.loading ||
    eventsReturn.loading
  )
    return <Loading />;
  else if (purchasesReturn.error || drawdownsReturn.error || eventsReturn.error)
    return (
      <ErrorView
        error={
          purchasesReturn.error || drawdownsReturn.error || eventsReturn.error
        }
      />
    );
  const tokenBreakdown = {
    // "Token Name": {
    //   name: "Token Name",
    //   amount: 50,
    //   value: 500
    // }
  };
  const sdgGroupBreakdown = {
    health: {
      amount: 0,
      value: 0
    },
    environment: {
      amount: 0,
      value: 0
    },
    animal: {
      amount: 0,
      value: 0
    },
    social: {
      amount: 0,
      value: 0
    }
  };
  let lastTransaction;
  let lastDelivery;
  const sdgsContributedTo = new Set();
  let biggestAmount = 0;
  let totalMissions = 0;
  const totalOutcomes = { amount: 0, value: 0 };
  function addTransaction(transaction) {
    if (tokenBreakdown[transaction.event.token.name]) {
      tokenBreakdown[transaction.event.token.name].amount += transaction.amount;
      tokenBreakdown[transaction.event.token.name].value +=
        transaction.amount *
        (transaction.price || transaction.commitment.price);
    } else {
      tokenBreakdown[transaction.event.token.name] = {
        name: transaction.event.token.name,
        amount: transaction.amount,
        value:
          transaction.amount *
          (transaction.price || transaction.commitment.price)
      };
    }
    const group = Object.keys(sdgGroups).find(group =>
      sdgGroups[group].includes(transaction.event.token.sdgs[0])
    );
    sdgGroupBreakdown[group].value +=
      transaction.amount * (transaction.price || transaction.commitment.price);
    totalOutcomes.value +=
      transaction.amount * (transaction.price || transaction.commitment.price);
    sdgsContributedTo.add(transaction.event.token.sdgs[0]);
    if (!lastTransaction || lastTransaction.timestamp < transaction.timestamp)
      lastTransaction = transaction;
  }
  purchasesReturn.data.purchases.forEach(addTransaction);
  drawdownsReturn.data.commitDrawdowns.forEach(addTransaction);
  eventsReturn.data.events.forEach(event => {
    const group = Object.keys(sdgGroups).find(group =>
      sdgGroups[group].includes(event.token.sdgs[0])
    );
    sdgGroupBreakdown[group].amount += event.amount;
    totalOutcomes.amount += event.amount;
    totalMissions++;
    biggestAmount = Math.max(biggestAmount, event.amount);
    if (!lastDelivery || lastDelivery.timestamp < event.timestamp)
      lastDelivery = event;
  });
  const pieData = Object.values(tokenBreakdown);
  return (
    <Grid container style={{ marginBottom: "25px" }}>
      <div style={{ height: "50px" }} />
      <SubHeader />
      <Container maxWidth="lg" style={{ marginTop: "65px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Totals totalOutcomes={totalOutcomes} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Graphs pieData={pieData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AtAGlance
              totalMissions={totalMissions}
              sdgsContributedTo={sdgsContributedTo}
              biggestAmount={biggestAmount}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {/* <TypeTotals sdgGroupBreakdown={sdgGroupBreakdown} /> */}
            <RegisteredEvents {...props}></RegisteredEvents>
          </Grid>
          <Grid item xs={12} md={7}>
            <MissionLocation
              location={{
                longitude: lastDelivery && lastDelivery.longitude,
                latitude: lastDelivery && lastDelivery.latitude
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <MissionDetails lastTransaction={lastTransaction} />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default withApollo(SellerDashboard);
