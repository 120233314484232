import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import whiteLogo from "../../assets/images/logo no text white.png";

const useStyles = makeStyles(theme => ({
  button: {
    // background: theme.palette.primary.main,
    background: "#5270b1",
    color: "white",
    borderRadius: "5px",
    maxHeight: "50px",
    padding: "10px",
    textAlign: "center",
    margin: theme.spacing(4)
  }
}));

function Title({ details, handlers, user, loggedIn }) {
  const { button } = useStyles();
  const { eventName } = details;
  return (
    <>
      <Grid>
        <Typography variant="h4" style={{ marginBottom: "45px" }}>
          Welcome,{" "}
          {user.nickname || user.username || user.profileData.organization}!
        </Typography>
        <img src={whiteLogo} alt="" width="75" height="69" />
      </Grid>
      <br />
      <Grid item container justify="center">
        <Button className={button} onClick={() => handlers.navigateAndClear()}>
          {`Submit ${eventName} event`}
        </Button>
      </Grid>
    </>
  );
}

export default Title;
