import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import EventCard from "../shared/EventCard";
import FeaturedEvent from "./FeaturedEvent";
import FilteredSDG from "./FilteredSDG";
import eventsGrid from "./EventsGrid";

const MarketplaceListGrid = styled.div`
  display: grid;
  grid-area: content;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1.5rem;
  padding: 4rem 2rem;
  padding-bottom: 0rem;
  max-width: 100%;
  height: fit-content;

  section {
    grid-column: 1/-1;
    @media only screen and (min-width: 770px) {
      grid-column: 2 / span 4;
    }
  }
  .featured {
    grid-column: 1/-1;
  }
  .odd {
    grid-column: 1/-1;
    @media only screen and (min-width: 770px) {
      grid-column: span 3;
    }
  }
  .even {
    grid-column: 1/-1;
    @media only screen and (min-width: 770px) {
      grid-column: span 2;
    }
  }
  @media only screen and (min-width: 770px) {
    padding: 4em 4em 5em 2em;
    .coming-soon {
      grid-column: span 2;
    }
  }
`;

function MarketplaceList(props) {
  const { filter, eventData = {} } = props;
  const {
    mainEvents,
    featuredEvent,
    featuredHeader,
    featuredDescription,
    comingSoonEvents
  } = eventData;

  return (
    <>
      <MarketplaceListGrid>
        {filter ? (
          <FilteredSDG
            filter={props.filter}
            events={[featuredEvent, ...mainEvents]}
          />
        ) : (
          <>
            <div className="featured">
              {featuredEvent && (
                <FeaturedEvent
                  event={featuredEvent}
                  featuredHeader={featuredHeader}
                  featuredDescription={featuredDescription}
                />
              )}
            </div>
            {eventsGrid(mainEvents).map((row, idx) => {
              return (
                <React.Fragment key={`events-${idx}`}>{row}</React.Fragment>
              );
            })}
            <Typography variant="h3" style={{ gridColumn: "1/-1" }}>
              Coming Soon
            </Typography>
            {comingSoonEvents.map((event, i) => {
              return (
                <section className="coming-soon" key={i}>
                  <EventCard event={event} />
                </section>
              );
            })}
          </>
        )}
      </MarketplaceListGrid>
    </>
  );
}

export default MarketplaceList;
