import React from "react";
import { Grid, TextField } from "@material-ui/core";

function FieldInput({ values, setValues, details, edit }) {
  const { type, placeholder, stateVar, title, required } = details;
  return (
    <>
      <Grid item xs={12} md={7} lg={7}>
        <TextField
          label={edit ? title : ""}
          fullWidth
          required={required}
          type={type}
          margin="normal"
          variant="outlined"
          value={values[stateVar]}
          placeholder={placeholder}
          onChange={({ target: { value } }) =>
            setValues({ ...values, [stateVar]: value })
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </>
  );
}

export default FieldInput;
