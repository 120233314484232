import React from "react";
import { Link, NavLink } from "react-router-dom";

// https://material-ui.com/components/buttons/#third-party-routing-library

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const AdapterNavLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export { AdapterLink, AdapterNavLink };
