import React from "react";

import ErrorView from "../ErrorView";
import Purchase from "./Purchase";
import Order from "./Order";

function PurchaseReport(props) {
  if (props.match.params.orderId) {
    return <Order {...props} />;
  } else if (props.match.params.purchaseId) {
    return <Purchase {...props} />;
  } else {
    return <ErrorView />;
  }
}

export default PurchaseReport;
