export const sdgNames = {
  1: "No Poverty",
  2: "Zero Hunger",
  3: "Good Health and Well-Being",
  4: "Quality Education",
  5: "Gender Equality",
  6: "Clean Water and Sanitation",
  7: "Affordable and Clean Energy",
  8: "Decent Work and Economic Growth",
  9: "Industry, Innovation and Infrastructure",
  10: "Reduced Inequalities",
  11: "Sustainable Cities and Communities",
  12: "Responsible Consumption and Production",
  13: "Climate Action",
  14: "Life Below Water",
  15: "Life on Land",
  16: "Peace, Justice and Strong Institutions",
  17: "Partnerships for the Goals"
};

export const filterCategories = {
  Environmental: {
    color: "#106640",
    sub: {
      7: "Affordable and Clean Energy",
      11: "Sustainable Cities and Communities",
      12: "Responsible Consumption and Production",
      13: "Climate Action"
    }
  },
  Social: {
    color: "#e8ab48",
    sub: {
      1: "No Poverty",
      2: "Zero Hunger",
      4: "Quality Education",
      5: "Gender Equality",
      8: "Decent Work and Economic Growth",
      9: "Industry, Innovation, and Infrastructure",
      10: "Reducing Inequality",
      16: "Peace, Justice, and Strong Institutions",
      17: "Partnerships for the Goals"
    }
  },
  Health: {
    color: "#4371cc",
    sub: {
      3: "Good Health and Well-being",
      6: "Clean Water and Sanitation"
    }
  },
  Animal: {
    color: "#de896f",
    sub: {
      14: "Life Below Water",
      15: "Life On Land"
    }
  }
};
