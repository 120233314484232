import styled from "styled-components";
import { Grid, Typography, Button, Input, Modal } from "@material-ui/core";
import GraphImg from "graphcms-image";

import MissionStartCardBackgroundImage from "../../assets/images/mission-start-card-background.png";

/* index */
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-template-rows: 100vh;
  grid-template-areas:
    "scrollpanel"
    "fundingpanel";

  @media only screen and (min-width: 960px) {
    grid-template-areas: "scrollpanel fundingpanel";
    grid-template-columns: 5fr 2fr;
  }
  section {
    grid-column: 1/-1;
    grid-row: 1/-1;
  }
  .back-button {
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    justify-self: center;
  }
`;

const ScrollPanel = styled.div`
  grid-area: scrollpanel;
`;

const StickySidebar = styled.div`
  position: fixed;
  margin-right: 1.5rem;
  display: flex;
  flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex-direction: column;
  height: 93.5vh;
  justify-content: space-between;
`;

const FundingPanel = styled.div`
  grid-area: fundingpanel;
  display: grid;
  margin: 0rem 2rem;
  height: fit-content;
  grid-gap: 0.5rem;

  @media only screen and (min-width: 770px) {
    margin: 0 2rem 0 0;
  }
`;

/* MissionStart */
const PositionedArrow = styled.div`
  position: absolute;
  z-index: 3;
  margin: 10px 0px;
  grid-row: 5;
  justify-self: center;
  .arrow {
    position: relative;
    bottom: -2rem;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 40px;
  }

  .bounce {
    animation: bounce 2s infinite;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`;

const MissionStartCard = styled(Grid)`
  top: 15%;
  transform: translateX(0%);
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "inconsolatabold";
  filter: drop-shadow(0px 6px 3px rgba(50, 50, 0, 0.2));
  padding: 0 1rem;

  @media only screen and (min-width: 770px) {
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    width: 90%;
    max-width: 700px;
  }

  .mission-text {
    display: grid;
    z-index: 5;
    width: 80%;
    margin-left: 10%;
    max-height: 400px;
    grid-gap: 1rem;
    /* font-size: 0.8rem; */
  }

  & .card {
    padding: 20px;
    background: url(${MissionStartCardBackgroundImage});
    clip-path: polygon(1.5% 1%, 99% 2%, 99.5% 98%, 1% 97%);
    width: 100%;

    & .content-wrapper {
      display: grid;
      grid-template-columns: 2fr 0fr;
      border: solid 1px rgba(85, 79, 71, 0.4);
      color: #171a1d;

      & .details {
        overflow: hidden;
      }

      & .lines {
        background: repeating-linear-gradient(
          transparent,
          transparent 20px,
          rgba(85, 79, 71, 0.4) 21px,
          rgba(85, 79, 71, 0.4) 21px
        );
        background-position: 0px 0px;
        padding: 0px 10px;
        margin-top: 20px;
        height: 100%;
      }

      & .mission-card-header {
        padding: 0px 10px;
        padding-top: 20px;
        border-bottom: solid 1px rgba(85, 79, 71, 0.4);
        display: flex;
      }

      & .card-text {
        line-height: 26px;
      }

      & .side-header {
        border-left: solid 1px rgba(85, 79, 71, 0.4);
        text-align: center;
        writing-mode: vertical-rl;
        padding: 30px 30px;
        text-transform: uppercase;

        header {
          h6 {
            letter-spacing: 3px;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
`;

const MissionStartContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  align-items: center;

  .back-button {
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    justify-self: center;
  }
`;

/* MissionStep */
const Header = styled(Grid)`
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HeaderContainer = styled.div`
  background-image: ${props => props.background};
  position: absolute;
  z-index: 1;
  display: grid;
  grid-template-columns: 100%;
  color: ${props => props.text};
  height: 50% !important;
`;

/* MissionPopover */
const TextBox = styled.div`
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 6fr;

  @media only screen and (min-width: 770px) {
    padding: 5rem;
  }
`;

const PopupBody = styled.div`
  letter-spacing: -0.25px;
  text-align: center;
  margin: auto 0;
`;

const PositionedModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopoverContainer = styled.span`
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  position: absolute;
  z-index: 4;
  height: fit-content !important;
  width: fit-content !important;
`;

/* MissionFundingForm */
const FundingOptionButton = styled(Button)`
  width: 100%;
  border-radius: 4px;
  border: solid 2px #f2f2f2;
  background-color: #f2f2f2;
  color: ${props => props.theme.palette.primary.main};
  margin: 3px 0px;
  text-align: left;
  padding-left: 10px;
  text-transform: none;
  font-family: "proxima_nova_abold";
  border: ${props => {
    return props.isActive ? "solid 2px #4371cc" : "solid 1px #d9d9d9";
  }};

  span {
    display: grid;
    grid-template-columns: 1fr 5fr;
  }
`;

const FundingSidebarContainer = styled.div`
  margin: 2.5rem 0rem;
`;

const DonateButton = styled(Button)`
  width: 100%;
  height: 64px;
  border-radius: 4px;
  background-color: #4371cc;
  margin: 3px 0px;
  color: white;
`;

const FundingInput = styled(Input)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  background-color: #f2f2f2;
  margin: 3px 0px;
  padding-left: 10px;
`;

/* MissionSummary */
const PositionedGrid = styled(Grid)`
  position: absolute;
  z-index: 1;
  height: 100%;
  color: white;
  text-align: center;
  padding: 0% 5%;
`;

const BoldFont = styled(Typography)`
  font-weight: bold;
`;

/* MissionFinal */
const SDGIcon = styled(GraphImg)`
  position: absolute;
  z-index: 3;
  width: 5em;
`;

const MissionFinalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    /* "leftpanel" */
    "rightpanel"
    "discover";
  height: 100vh;

  ${props => props.theme.breakpoints.up("sm")} {
    /* grid-template-columns: repeat(2, minmax(250px, 1fr)); */
    grid-template-rows: 2fr 1fr;
    /* grid-template-areas:
    "leftpanel rightpanel"
    "rightpanel rightpanel"
      "discover discover"; */
  }
  ${props => props.theme.breakpoints.up("lg")} {
    /* grid-template-columns: repeat(2, minmax(350px, 1fr)); */
    grid-template-rows: 2fr 1fr;
    /* grid-template-areas:
    "leftpanel rightpanel"
    "rightpanel rightpanel"
      "discover discover"; */
  }
`;

const Panel = styled(Grid)`
  display: grid;
  padding: 5rem 3rem;
  color: white;
  grid-gap: 1rem;
  width: 100%;
  ${props => props.theme.breakpoints.up("sm")} {
    padding: 5rem;
  }
`;

const CommunityImpact = styled(Panel)`
  grid-area: leftpanel;
  background: #106640;
`;

const GlobalContribution = styled(Panel)`
  grid-area: rightpanel;
  background: #16161a;
  .sdg-image {
    object-fit: contain;
    height: auto;
    max-width: 100%;
  }
`;

const DiscoverPanel = styled.div`
  grid-area: discover;
  background: #faf8f5;
  @media only screen and (min-width: 770px) {
    padding: 3.5rem 5rem;
  }
`;

const IconTextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  div > a {
    text-decoration: none;
    color: white;
  }
  .graphcms-image-outer-wrapper {
    padding-top: 0.5rem;
  }
`;

const EventGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1em;
  margin: 1em;
  list-style: none;
`;

export {
  PositionedArrow,
  MissionStartContainer,
  MissionStartCard,
  FundingOptionButton,
  DonateButton,
  FundingInput,
  BoldFont,
  TextBox,
  PositionedGrid,
  Container,
  PopoverContainer,
  PopupBody,
  PositionedModal,
  ScrollPanel,
  FundingPanel,
  Header,
  HeaderContainer,
  SDGIcon,
  FundingSidebarContainer,
  MissionFinalGrid,
  EventGrid,
  Panel,
  CommunityImpact,
  GlobalContribution,
  DiscoverPanel,
  IconTextContainer,
  StickySidebar
};
