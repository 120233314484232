import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { useAuth0 } from "../Auth0Provider";

function SubHeader() {
  const { logout, user } = useAuth0();
  const isSeller = user["https://poip.io/roles"][0] === "SELLER";
  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "#16161a", marginTop: "48px", height: "48px" }}
      elevation={3}
    >
      <Container>
        <Toolbar variant="dense">
          <div style={{ flexGrow: 1 }} />
          <Link
            to="/profile"
            style={{
              textDecoration: "inherit",
              color: "white",
              marginRight: "30px"
            }}
          >
            <Typography variant="body2">Dashboard</Typography>
          </Link>
          <Link
            to="/profile/missions"
            style={{
              textDecoration: "inherit",
              color: "white",
              marginRight: "30px"
            }}
          >
            <Typography variant="body2">Missions</Typography>
          </Link>
          {/* <Link
            to="/profile/manage"
            style={{
              textDecoration: "inherit",
              color: "grey"
            }}
            disabled
          >
            Manage Profile
          </Link> */}
          {isSeller ? (
            <Link
              to="/events/dashboard"
              style={{
                textDecoration: "inherit",
                color: "white",
                marginRight: "30px"
              }}
            >
              <Typography variant="body2">Submitted Data</Typography>
            </Link>
          ) : null}
          <Link
            to="#"
            onClick={() => logout({ returnTo: window.location.origin })}
            style={{
              textDecoration: "inherit",
              color: "white",
              marginRight: "30px"
            }}
          >
            <Typography variant="body2">Log Out</Typography>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default SubHeader;
