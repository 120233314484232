import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { Grid, useMediaQuery } from "@material-ui/core";
import { EVENTPAGE } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import MissionStart from "./MissionStart";
import MissionFinal from "./MissionFinal";
import MissionFundingForm from "./MissionFundingForm";
import MissionComingSoon from "./MissionComingSoon";
import {
  Container,
  ScrollPanel,
  FundingPanel,
  StickySidebar
} from "./MissionStyles";
import MissionSignUpForm from "./MissionSignUpForm";
import ImpactScroller from "../../components/ImpactScroller";
import BottomSheet from "../../components/shared/BottomSheet";

function MissionPage({ client, uuid, ...props }) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const isSignUp = props.match.path === "/signup";
  const { loading, error, data } = useQuery(EVENTPAGE, {
    variables: {
      route: isSignUp ? "signup" : props.match.params.tokenRoute
    },
    client
  });

  if (loading && !data) return <Loading />;
  if (error || !data.eventPage) return <ErrorView error={error} />;

  const {
    route,
    isActive,
    eventPageMissionStart,
    eventPageMissionSteps,
    eventPageMissionFinal,
    eventPageFundingForm,
    sdgs
  } = data.eventPage;
  const activeEvent = isActive === "True";
  if (
    eventPageMissionStart === null ||
    ["header", "backgroundImage", "description"].some(
      key => eventPageMissionStart[key] == null
    )
  ) {
    return (
      <h1>
        {eventPageMissionStart
          ? `Oops this event does not have ${[
              "header, backgroundImage",
              "description"
            ].filter(key => eventPageMissionStart[key] == null)}`
          : `This event does not contain a start parameter.`}
      </h1>
    );
  }

  const stepperPrefixElements = [
    <Grid item key={"mission-start"}>
      <MissionStart data={eventPageMissionStart} activeEvent={activeEvent} />
    </Grid>
  ];

  const stepperPostfixElements = [
    <MissionFinal
      data={eventPageMissionFinal}
      sdgs={sdgs}
      route={route}
      signupMission={isSignUp}
      key={"mission-final"}
    />
  ];

  const formElement = !isSignUp ? (
    <MissionFundingForm
      data={eventPageFundingForm}
      sdgs={sdgs}
      activeEvent={activeEvent}
      {...props}
    />
  ) : (
    <MissionSignUpForm event={eventPageFundingForm} sdgs={sdgs} />
  );

  return (
    <>
      {activeEvent ? (
        <Container>
          <ScrollPanel>
            <ImpactScroller
              contentLoading={loading}
              stepperPrefixElements={stepperPrefixElements}
              eventPageMissionSteps={eventPageMissionSteps}
            />
            {stepperPostfixElements}
          </ScrollPanel>
          {isDesktop ? (
            <FundingPanel>
              <StickySidebar>{formElement}</StickySidebar>
            </FundingPanel>
          ) : (
            <BottomSheet buttonText={isSignUp ? "Sign up" : "Donate"}>
              {formElement}
            </BottomSheet>
          )}
        </Container>
      ) : (
        <MissionComingSoon event={data.eventPage} {...props} />
      )}
    </>
  );
}

export default withApollo(MissionPage);
