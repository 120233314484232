import gql from "graphql-tag";

const NEWUSER = gql`
  mutation newUser(
    $username: String!
    $password: String!
    $email: String!
    $role: String
  ) {
    newUser(
      user: {
        username: $username
        password: $password
        email: $email
        role: $role
      }
    ) {
      username
      authId
    }
  }
`;

const CREATEEVENT = gql`
  mutation createEvent(
    $id: Int
    $amount: Int
    $timestamp: DateTime
    $latitude: String
    $longitude: String
    $tokenAddress: String!
    $metadata: JSON
  ) {
    createEvent(
      event: {
        id: $id
        amount: $amount
        timestamp: $timestamp
        latitude: $latitude
        longitude: $longitude
        tokenAddress: $tokenAddress
        metadata: $metadata
      }
    ) {
      id
    }
  }
`;

const SUBMITEVENT = gql`
  mutation submitEvent($id: Int!) {
    submitEvent(id: $id) {
      id
    }
  }
`;

const APPROVESUBMITTEDEVENT = gql`
  mutation approveSubmittedEvent($id: Int!) {
    approveSubmittedEvent(id: $id) {
      status
    }
  }
`;

const DENYSUBMITTEDEVENT = gql`
  mutation denySubmittedEvent($id: Int!) {
    denySubmittedEvent(id: $id) {
      status
    }
  }
`;

const UPLOADANDPINFILE = gql`
  mutation uploadAndPinFile($file: Upload!) {
    uploadAndPinFile(file: $file)
  }
`;

const APPROVEORDER = gql`
  mutation approveOrder($orderId: String!) {
    approveOrder(orderId: $orderId) {
      amount
      price
      timestamp
    }
  }
`;

const SETPURCHASEPAID = gql`
  mutation setPurchasePaid($id: Int!) {
    setPurchasePaid(id: $id) {
      id
      sellerPaid
    }
  }
`;

const SETDRAWDOWNPAID = gql`
  mutation setDrawdownPaid($id: Int!) {
    setDrawdownPaid(id: $id) {
      id
      sellerPaid
    }
  }
`;

const NEWMANUALPURCHASE = gql`
  mutation newManualPurchase(
    $purchases: [PurchaseInput]
    $commitment: CommitmentInput
    $buyer: String!
  ) {
    newManualPurchase(
      purchases: $purchases
      commitment: $commitment
      buyer: $buyer
    )
  }
`;

const SUBMITCONTACTFORM = gql`
  mutation submitContactForm(
    $formDetails: SubmitContactFormInput
  ) {
    submitContactForm(
      formDetails: $formDetails
    )
  }
`;

export {
  NEWUSER,
  SUBMITEVENT,
  CREATEEVENT,
  APPROVESUBMITTEDEVENT,
  DENYSUBMITTEDEVENT,
  UPLOADANDPINFILE,
  APPROVEORDER,
  SETPURCHASEPAID,
  SETDRAWDOWNPAID,
  NEWMANUALPURCHASE,
  SUBMITCONTACTFORM
};
