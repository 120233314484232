import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MissionStart from "./MissionStart";
import BottomSheet from "../shared/BottomSheet";
import { Container, ScrollPanel, FundingPanel } from "./MissionStyles";
import MissionFundingForm from "./MissionFundingForm";
import { RoundedButton } from "../shared/StyledComponents";

const PositionedButton = styled(Link)`
    position: absolute;
    z-index: 2;
    margin: 2rem;
`;

function MissionComingSoon(props) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const {
    eventPageMissionStart,
    eventPageFundingForm,
    sdgs,
    isActive
  } = props.event;
  const fundingForm = (
    <MissionFundingForm
      data={eventPageFundingForm}
      sdgs={sdgs}
      isActive={isActive}
      {...props}
    />
  );
  return (
    <Container>
      <ScrollPanel>
        <section>
          <PositionedButton to="/">
            <RoundedButton className={"backButton"}>
              <ChevronLeft />
              {isDesktop ? "Back" : null}
            </RoundedButton>
          </PositionedButton>
          <MissionStart data={eventPageMissionStart}></MissionStart>
        </section>
      </ScrollPanel>
      <FundingPanel>
        {isDesktop ? (
          <>{fundingForm}</>
        ) : (
          <BottomSheet buttonText="Sign up">{fundingForm}</BottomSheet>
        )}
      </FundingPanel>
    </Container>
  );
}

export default MissionComingSoon;
