import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { CTAButton } from "./StyledComponents";
import styled from "styled-components";

const Button = styled(CTAButton)`
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 0;
  width: 100%;
`;

const List = styled.div`
  padding: 0 1rem;
  height: 75vh;
  border-radius: 8px 8px 0px 0px;
  width: auto;
`;

export default function BottomSheet(props) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  return (
    <div>
      <Button onClick={toggleDrawer(true)} height={"3.7rem"}>
        {props.buttonText}
      </Button>

      <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
        <List role="presentation">{props.children}</List>
      </Drawer>
    </div>
  );
}
