// DEMO ONLY
import React from "react";
import { Container } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import DashboardTable from "../shared/DashboardTable";
import { SUBMITTEDEVENTS } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

function InputDashboard({ client, ...props }) {
  const { loading, error, data } = useQuery(SUBMITTEDEVENTS, {
    variables: { seller: props.user["https://poip.io/username"] },
    client
  });

  if (loading) return <Loading />;
  if (error) return <ErrorView error={error} />;
  return (
    <Container>
      <br />
      <br />
      <h1>Impact Events Panel</h1>
      <br />
      <h5>Edit and Submit Impact Events</h5>
      <DashboardTable
        submittedEvents={data.submittedEvents}
        {...props}
      ></DashboardTable>
    </Container>
  );
}

export default withApollo(InputDashboard);
