import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import DashboardTable from "../shared/DashboardTable";
import { SUBMITTEDEVENTS_WITH_PAGINATION } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

function SubmittedTable({ client, ...props }) {
  const [checked, setChecked] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDirection, setOrderDirection] = useState(null);
  const { loading, error, data } = useQuery(SUBMITTEDEVENTS_WITH_PAGINATION, {
    variables: { 
      limit: perPage, 
      offset: page, orderBy: orderDirection !== null ? {
      attribute: orderBy,
      order: orderDirection
    } : null },
    client
  });

  let events = []; 
  if(data && totalRows !== data.submittedEventsWithPagination.total){
    setTotalRows(data.submittedEventsWithPagination.total)
  }

  if (data) {
    const filteredEvents = data.submittedEventsWithPagination.events.filter(event => event.status === "PENDING");
    events = checked ? data.submittedEventsWithPagination.events : filteredEvents;
  }

  const handlePageChange = async page => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(page);
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setOrderBy(column.selector)
    setOrderDirection(sortDirection.toUpperCase())
  };

  return (
    <>
      {data && data.submittedEventsWithPagination && (
        <div>
          <Typography>Total Pending: {data.submittedEventsWithPagination.totalPending}</Typography>
          <Typography>Total Approved (Live): {data.submittedEventsWithPagination.totalApproved}</Typography>
          <Typography>Total Working: {data.submittedEventsWithPagination.totalWorking}</Typography>
          <Typography>Total Denied: {data.submittedEventsWithPagination.totalDenied}</Typography>
        </div>
      )}
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
            />
          }
          label="View all"
        />
      </FormGroup>
      {loading && events.length === 0 ? (
        <Loading />
      ) : error ? (
        <ErrorView error={error} />
      ) : (
        <div>
          <DashboardTable admin submittedEvents={events} {...props} totalEvents={totalRows} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} handleSort={handleSort}/>
        </div>
      )}
    </>
  );
}

export default withApollo(SubmittedTable);
