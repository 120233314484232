import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, CardContent, Typography } from "@material-ui/core";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

import ErrorView from "../ErrorView";
import Loading from "../Loading";
import { PRICES } from "../../utilities/queries";
import {
  PNSemiBold,
  StyledImg as ImgBase,
  StyledGraphImg as GraphImgBase
} from "../shared/StyledComponents";

const StyledImg = styled(ImgBase)`
  border-radius: 5px 5px 0px 0px;
`;

const StyledGraphImg = styled(GraphImgBase)`
  border-radius: 5px 5px 0px 0px;
`;

const Card = styled(CardContent)`
  color: black;
  padding: 1rem 0rem;
  .caption {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

const categoryColors = {
  Environmental: "#2E6443",
  Social: "#DFAE5A",
  Health: "#4E71C6",
  Animal: "#D38D74"
};

function EventCard({ event, token, height, width, minPrice, client }) {
  let {
    eventPage,
    sdgs: primarySdgs,
    description,
    route,
    displayImage,
    displayDescription,
    impactCategory
  } = event ? event : token;
  const { loading, data, error } = useQuery(PRICES, {
    variables: { route },
    client
  });
  if (!event && !token) return null;

  const borderColor = eventPage
    ? categoryColors[eventPage.impactCategory]
    : categoryColors[impactCategory];

  if (loading && !data) return <Loading />;
  if (error) {
    return <ErrorView error={error} />;
  }
  return (
    <Link
      to={route === "signup" ? "/signup" : `/events/${route}`}
      style={{ textDecoration: "none" }}
    >
      <Box boxShadow={0} width={width} style={{margin: '0 auto'}}>
        {displayImage || (eventPage && eventPage.eventPageMissionStart) ? (
          <StyledGraphImg
            image={
              displayImage || eventPage.eventPageMissionStart.backgroundImage
            }
            className="d-block w-100"
            borderColor={borderColor}
            height={height}
          />
        ) : (
          <StyledImg
            src={require(`../../assets/images/sdg/sdg_icon_${
              typeof primarySdgs[0] === "number"
                ? primarySdgs[0].toString()
                : primarySdgs[0].number.toString()
            }.png`)}
            borderColor={borderColor}
            height={height}
            alt="impact event"
          />
        )}
        <Card>
          <div className="caption">
            {(eventPage && eventPage.sdgs) ||
            typeof primarySdgs[0] === "object" ? (
              <PNSemiBold variant="h6" style={{ color: borderColor }}>
                {eventPage ? eventPage.impactCategory : impactCategory}
              </PNSemiBold>
            ) : null}
            {minPrice || data.prices.length ? (
              <Grid item lg={2}>
                <PNSemiBold variant="h6" align="right">
                  {`$${
                    minPrice
                      ? parseInt(minPrice).toFixed(2)
                      : data.prices[0].price
                  }`}
                </PNSemiBold>
              </Grid>
            ) : null}
          </div>
          <Typography variant="body1">
            {description || displayDescription}
          </Typography>
        </Card>
      </Box>
    </Link>
  );
}

export default withApollo(EventCard);
