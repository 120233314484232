import React from "react";
import { withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { Typography, Grid, Link, useMediaQuery } from "@material-ui/core";
import GraphImg from "graphcms-image";

import {
  Banner,
  PNSemiBold,
  CTAButton,
  TransparentButton,
  GridGap,
  BannerGraphImg
} from "../shared/StyledComponents";
import {
  HowItWorksContainer,
  HowItWorksContent,
  Quicksheet
} from "./HowItWorksStyles";

import { HOWITWORKS } from "../../utilities/queries";
import ErrorView from "../ErrorView";
import Loading from "../Loading";
import GettingStarted from "../shared/GettingStarted";

function HowItWorks({ client }) {
  const isDesktop = useMediaQuery("(min-width:960px)");
  const { loading, data, error } = useQuery(HOWITWORKS, { client });
  if (loading && !data) return <Loading />;
  if (error) {
    return <ErrorView error={error}></ErrorView>;
  }
  const {
    header,
    headerBanner,
    headerTextColor,
    howItWorksSteps,
    howItWorksIcons,
    howItWorksImage,
    whatIsImpact,
    provingImpact,
    ourMarketplace
  } = data.howItWorkses[0];

  const sortedSteps = {};
  Object.keys(howItWorksSteps)
    .sort()
    .forEach(key => {
      sortedSteps[key] = howItWorksSteps[key];
    });
  const hasButton = {
    // Commented out until we figure out where this button should lead
    // 1: ["Learn more about Impact Creators", ""],
    3: ["Explore the marketplace", "/"],
    4: ["View sample report", "https://media.graphcms.com/u2s5tAUnQF23hiZu4AJ5"]
  };
  const steps = Object.keys(sortedSteps).map((step, i) => {
    const button = hasButton[step] ? (
      <TransparentButton margin="1rem 0rem" maxWidth="302px">
        <a href={hasButton[step][1]}>{hasButton[step][0]}</a>
      </TransparentButton>
    ) : null;
    const [image] = howItWorksIcons.filter(
      icon => icon.fileName === `hiw-${i + 1}.png`
    );
    const icon = (
      <GraphImg
        image={image}
        style={{ maxWidth: "210px", margin: "0 auto" }}
      ></GraphImg>
    );
    const content = (
      <div className="content">
        <Typography variant="h5">
          {`${i + 1}. ${Object.keys(sortedSteps[step])[0]}`}
        </Typography>
        <PNSemiBold variant="body1">
          {Object.values(sortedSteps[step])[0]}
        </PNSemiBold>
        {button}
      </div>
    );
    return (
      <React.Fragment key={i}>
        {isDesktop ? (
          <div className="step">
            {parseInt(step) % 2 === 0 ? (
              <div className="icon-right">
                {content}
                {icon}
              </div>
            ) : (
              <div className="icon-left">
                {icon}
                {content}
              </div>
            )}
          </div>
        ) : (
          <GridGap>
            {icon}
            {content}
          </GridGap>
        )}
      </React.Fragment>
    );
  });

  return (
    <HowItWorksContainer>
      <Banner>
        <div className="banner-text">
          <Typography variant="h1" style={{ color: headerTextColor.hex }}>
            {header}
          </Typography>
        </div>
        <BannerGraphImg image={headerBanner} />
      </Banner>

      <GettingStarted />

      <HowItWorksContent>{steps}</HowItWorksContent>

      <Quicksheet>
        <div className="one-two-column">
          <Grid container direction="column">
            <Typography variant="h3">QuickSheet</Typography>
            <PNSemiBold variant="body1">
              New to our community? Here’s a few questions we can answer for
              you.
            </PNSemiBold>
          </Grid>
          <div className="right-content">
            <div>
              <Typography variant="h5">What is Impact?</Typography>
              <PNSemiBold variant="body1">{whatIsImpact}</PNSemiBold>
            </div>
            <div>
              <Typography variant="h5">Proving Impact</Typography>
              <PNSemiBold variant="body1">{provingImpact}</PNSemiBold>
            </div>
            <div className="bottom-row">
              <Typography variant="h5">Our Marketplace</Typography>
              <PNSemiBold variant="body1">{ourMarketplace}</PNSemiBold>

              <GraphImg image={howItWorksImage}></GraphImg>

              <Typography variant="h5">Learn More</Typography>
              <PNSemiBold variant="body1">
                Our model is different, innovative and full of potential. For
                the details of our technology and the possibilities, read our
                white paper.
              </PNSemiBold>
              <Link
                href="https://drive.google.com/file/d/1ZpIpMhiWPLed0VwTawPnRC_wi5qCZ5sf/view"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textTransform: "none" }}
              >
                <CTAButton width="20rem">Our Methodology</CTAButton>
              </Link>
            </div>
          </div>
        </div>
      </Quicksheet>
    </HowItWorksContainer>
  );
}

export default withApollo(HowItWorks);
