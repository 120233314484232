import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "./Auth0Provider";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetPath: path }
      });
    };
    if (process.env.REACT_APP_ALLOW_DONATIONS === "true") fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (process.env.REACT_APP_ALLOW_DONATIONS !== "true")
    return <Redirect to="/" />;

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
