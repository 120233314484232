import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { PNSemiBold } from "../shared/StyledComponents";

const StyledTab = styled(Tab)`
  font-family: "proxima_nova_asemibold";
  text-transform: none;
  font-size: 1em;
  text-decoration: inherit;
  color: inherit;
  min-width: auto;
`;

function FilterSubheader({ handleFilterMenu, selectedIndex }) {
  const filters = ["Environmental", "Social", "Health", "Animal"];
  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "#ededeb", marginTop: "48px", height: "48px" }}
      elevation={3}
    >
      <Container>
        <Toolbar variant="dense">
          <Tabs
            value={false}
            variant="scrollable"
            scrollButtons="auto"
            orientation="horizontal"
          >
            {filters.map((filter, i) => {
              return (
                <StyledTab
                  key={i}
                  component="button"
                  onClick={() => handleFilterMenu(filter, i)}
                  style={
                    selectedIndex === i
                      ? { color: "#4371cc" }
                      : { color: "black" }
                  }
                  label={<PNSemiBold variant="body2">{filter}</PNSemiBold>}
                ></StyledTab>
              );
            })}
          </Tabs>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default FilterSubheader;
